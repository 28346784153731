import { getEndpoint } from "../endpoints";

import {
	httpServiceInstance,
	type HttpService,
} from "./http.service";

/**
 * The UserService class provides methods for interacting with the user API.
 */
export class UserService {
	private static http: HttpService;
	private static endpoint: string;

	constructor() {
		// Private constructor to prevent instantiation
		UserService.http = httpServiceInstance;
		UserService.endpoint = getEndpoint();
	}

	async getUserMeta() {
		const url = `${UserService.endpoint}/api/user_meta`;
		try {
			const { data } =
				await UserService.http.get<Record<string, any>>(
					url
				);
			return data;
		} catch (error: any) {
			console.error(error);
			throw new Error(error.response);
		}
	}

	async getAllUsers() {
		const url = `${UserService.endpoint}/api/users`;
		try {
			const { data } =
				await UserService.http.get<Array<any>>(url);
			return data;
		} catch (error: any) {
			console.error(error);
			throw new Error(error.response);
		}
	}

	async addUser({
		email,
		name,
		role,
		tenant,
	}: {
		email: string;
		name: string;
		role: string;
		tenant: any;
	}) {
		const url = `${UserService.endpoint}/api/users`;
		const config = {
			data: {
				email_id: email,
				name,
				global_role: role,
				tenants: tenant,
			},
		};

		const { data } = await UserService.http.post<any>(
			url,
			config
		);
		return data;
	}

	async updateUser({
		uid,
		name,
		role,
		tenant,
	}: {
		uid: string;
		name?: string;
		role?: string;
		tenant?: any;
	}) {
		const url = `${UserService.endpoint}/api/users/${uid}`;
		const config: any = {
			data: {},
		};
		if (name) {
			config.data.name = name;
		}
		if (role) {
			config.data.global_role = role;
		}
		if (tenant) {
			config.data.tenants = tenant;
		}
		const { data } = await UserService.http.post<any>(
			url,
			config
		);
		return data;
	}

	async deleteUser(userId: string) {
		const url = `${UserService.endpoint}/api/users`;
		const config = {
			data: {
				uid: userId,
			},
		};
		const { data } = await UserService.http.delete<any>(
			url,
			config
		);
		return data;
	}

	async changePassword(password: string) {
		const url = `${UserService.endpoint}/api/users/change_password`;
		const config = {
			data: {
				new_password: password,
			},
		};
		try {
			const { data, status } = await UserService.http.post(
				url,
				config
			);
			return {
				success: status === 200,
				data,
			};
		} catch (error: any) {
			console.error(error);
			return {
				success: false,
				msg: error.response,
			};
		}
	}
}
