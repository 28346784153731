import type { TLitheQuestion } from "../types/lithe.type";

const uiNonEditableQuestions: Record<
	string,
	Array<string>
> = {
	"00000201-0000-0000-0000-000000000000": [
		"00000053-0000-0000-0000-000000000000",
		"00000054-0000-0000-0000-000000000000",
		"00000055-0000-0000-0000-000000000000",
	],
};

const isUiEditable = (
	questionId: string,
	categoryId: string
) => {
	if (
		!Object.keys(uiNonEditableQuestions).includes(
			categoryId
		)
	)
		return true;
	else if (
		uiNonEditableQuestions[categoryId].includes(questionId)
	) {
		return false;
	} else return true;
};

const addConstraintsToDataVizQuestions = {
	"00000042-0000-0000-0000-000000000000": {
		lowerBound: 946684800000,
		upperBound: 1717201800000,
	},

	"00000043-0000-0000-0000-000000000000": {
		lowerBound: 946684800000,
		upperBound: 1717201800000,
	},

	"00000046-0000-0000-0000-000000000000": {
		lowerBound: 0,
		upperBound: 0.6,
	},

	"00000047-0000-0000-0000-000000000000": {
		lowerBound: 1000,
		upperBound: 100000,
	},

	"00000048-0000-0000-0000-000000000000": {
		lowerBound: 12,
		upperBound: 360,
	},
};

export const extractQuestionsLithe = (
	data: Array<any> | null
) => {
	if (!data) return [];
	const res: Array<TLitheQuestion> = data.map(
		(value: any) => {
			let tempAnswer = value.answer;

			let masterOptions: Array<any> = [];

			if (
				value.answer_type_info.answer_type === "time_millis"
			) {
				tempAnswer = new Date(tempAnswer)
					.toISOString()
					.split("T")[0];
			} else if (
				value.answer_type_info.answer_type === "float_list"
			) {
				tempAnswer = JSON.stringify(value.answer);
			} else if (
				typeof value.answer === "object" &&
				value.category_id ===
					"00000201-0000-0000-0000-000000000000"
			) {
				tempAnswer = JSON.stringify(value.answer);
			}

			if (
				value.category_id ===
					"00000202-0000-0000-0000-000000000000" &&
				Object.keys(
					addConstraintsToDataVizQuestions
				).includes(value.question_id)
			) {
				const q = (addConstraintsToDataVizQuestions as any)[
					value.question_id
				];
				const ct = [
					{
						constraint_value: q.lowerBound,
						answer_constraint_type_id:
							"00000002-0000-0000-0000-000000000000",
						constraint_name: "min_value",
					},
					{
						constraint_value: q.upperBound,
						answer_constraint_type_id:
							"00000003-0000-0000-0000-000000000000",
						constraint_name: "max_value",
					},
				];
				value.answer_type_info.constraints = ct;
			}

			//  db_id.QUESTION_LITHE_ORIG_START_DATE: {
			//         vc.DEFAULT_VALUE: 1420050600000, # 2015
			//         vc.LOWER_BOUND: 1420050600000, # 2015
			//         vc.UPPER_BOUND: 1546281000000, # 2019

			//     },
			//     db_id.QUESTION_LITHE_ORIG_END_DATE: {
			//         vc.DEFAULT_VALUE: 1546281000000, # 2019
			//         vc.LOWER_BOUND: 1420050600000,
			//         vc.UPPER_BOUND: 1546281000000, # 2019
			//     },
			//     db_id.QUESTION_LITHE_INTEREST_RATE_RANGE: {
			//         vc.DEFAULT_VALUE: [0,1],
			//         vc.LOWER_BOUND: 0,
			//         vc.UPPER_BOUND: 1
			//     },
			//     db_id.QUESTION_LITHE_LOAN_AMOUNT_RANGE: {
			//         vc.DEFAULT_VALUE: [1000,25_000_000],
			//         vc.LOWER_BOUND: 1000,
			//         vc.UPPER_BOUND: 25_000_000
			//     },
			//     db_id.QUESTION_LITHE_TERM_RANGE: {
			//         vc.DEFAULT_VALUE: [0,720],
			//         vc.LOWER_BOUND: 0,
			//         vc.UPPER_BOUND: 720
			//     },
			//     db_id.QUESTION_LITHE_ADVANCED_FILTERS: {
			//         vc.DEFAULT_VALUE: ""
			//     }

			if (
				value.answer_type_info.answer_type ===
					"risk_factor_ranges" ||
				value.answer_type_info.answer_type ===
					"risk_factor_breakpoints"
			) {
				masterOptions = value.answer_type_info.options;
			} else if (
				value.answer_type_info.answer_type ===
				"option_selection"
			) {
				masterOptions = value.answer_type_info.options?.map(
					(option: any) => ({
						optionId: option.option_id,
						optionName: option.display_name,
					})
				);
			}

			let isEditable = true;

			if (value.is_ui_editable) {
				isEditable = value.is_ui_editable;
			} else {
				isEditable = isUiEditable(
					value.question_id,
					value.category_id
				);
			}

			return {
				question: value.question,
				questionId: value.question_id,
				answers: masterOptions,
				answerType: value.answer_type_info.answer_type,
				defaultAnswer: value.answer,
				validations: value.answer_type_info.constraints,
				isGenAIEnabled: value.is_genai_compatible,
				answer: tempAnswer,
				pythonValue: value.python_transformation_code,
				naturalTextValue: value.transform_description,
				categoryId: value.category_id,
				label: value.question,
				isProcessed: value.is_processed,
				isEditable,
			};
		}
	);
	return res;
};

/**
 * Generates all combinations of patterns from the combination object.
 * @param {Object} combinationObj - The input object with keys and their respective arrays of values.
 * @returns {Array} An array of patterns, where each pattern is an ordered array of key-value pairs.
 */

export const generatePatterns = (
	combinationObj: Record<string, Array<string>>,
	sortedKeys: Array<string>
) => {
	const keys = sortedKeys;
	const values: Array<any> = [];
	keys.forEach((key) => {
		values.push(combinationObj[key]);
	});

	// Recursive function to compute Cartesian product
	function cartesianProduct(
		arrays: Array<any>,
		prefix: Array<any> = []
	) {
		if (!arrays.length) return [prefix];
		const [first, ...rest] = arrays;
		return first.flatMap((value: any) =>
			cartesianProduct(rest, [...prefix, value])
		);
	}

	// Generate Cartesian product for values
	const valueCombinations = cartesianProduct(values);
	// Map value combinations to key-value patterns
	return valueCombinations.map((valueCombo: any) => {
		const pattern: Array<any> = [];
		keys.forEach((key, index) => {
			pattern.push(key, valueCombo[index]);
		});
		return pattern;
	});
};
export const litheStatusOptions = [
	{
		label: "Any Status",
		value: "any",
		backgroundColor: "#FFFFFF",
		fontColor: "#000000",
	},
	{
		label: "Lithe Initialized",
		value: "not_started",
		backgroundColor: "#EFF8FF",
		fontColor: "#2E90FA",
	},
	{
		label: "Training Data Selection",
		value: "data_viz_started",
		backgroundColor: "#FFF6E5",
		fontColor: "#FFA800",
	},
	{
		label: "Risk Factor Filter Selection",
		value: "cashflow_viz_started",
		backgroundColor: "#EFF8FF",
		fontColor: "#2E90FA",
	},
	{
		label: "Define Buckets",
		value: "probs_started",
		backgroundColor: "#EFF8FF",
		fontColor: "#2E90FA",
	},
	{
		label: "Training Data Ready",
		value: "data_viz_completed",
		backgroundColor: "#CCFBEF",
		fontColor: "#0E9384",
	},
	{
		label: "Risk Factors Filtered",
		value: "cashflow_viz_completed",
		backgroundColor: "#F4F3FF",
		fontColor: "#6938EF",
	},
	{
		label: "Probabilities Calculated",
		value: "probs_completed",
		backgroundColor: "#EDFCF2",
		fontColor: "#16B364",
	},
	{
		label: "Lithe Approved",
		value: "approval_completed",
		backgroundColor: "#EDFCF2",
		fontColor: "#16B364",
	},
];

export const getLitheStatus = (status: string) => {
	return (
		litheStatusOptions.find(
			(option) => option.value === status
		) ?? litheStatusOptions[0]
	);
};
