import { createSlice } from "@reduxjs/toolkit";

import { type TTenantState } from "../../types/tenant.type";
import {
	getActiveTenant,
	setActiveTenant,
} from "../../utils/tenantUtils";

import {
	getAllAvailableTenantThunk,
	getAllTenantsForUser,
	getAllUsersInTenant,
} from "./tenant.thunk";

const initialState: TTenantState = {
	allTenants: null,
	activeTenant: null,
	allUsersInTenant: [],
	loading: false,
	error: null,
	allAvailableTenants: {
		data: [],
		loading: false,
		error: null,
	},
};

export const tenantSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setActiveTenantReducer: (state, action) => {
			state.activeTenant = action.payload;
			setActiveTenant(action.payload as string);
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAllTenantsForUser.pending, (state) => {
				state.loading = true;
			})
			.addCase(
				getAllTenantsForUser.fulfilled,
				(state, action) => {
					state.allTenants = action.payload;
					const previousActiveTenant = getActiveTenant();
					const allTenantKeys = Object.keys(action.payload);
					if (previousActiveTenant) {
						if (
							allTenantKeys.includes(previousActiveTenant)
						) {
							setActiveTenant(previousActiveTenant);
							state.activeTenant = previousActiveTenant;
						} else {
							const key = allTenantKeys[0];
							setActiveTenant(key);
							state.activeTenant = key;
						}
						state.loading = false;
					} else {
						if (allTenantKeys.length >= 1) {
							const key = allTenantKeys[0];
							setActiveTenant(key);
							state.activeTenant = key;
							state.loading = false;
						}
					}
				}
			)
			.addCase(
				getAllTenantsForUser.rejected,
				(state, action) => {
					state.loading = false;
					state.error = action.error as string;
				}
			);
		builder
			.addCase(getAllUsersInTenant.pending, (state) => {
				state.loading = true;
			})
			.addCase(
				getAllUsersInTenant.fulfilled,
				(state, action) => {
					state.allUsersInTenant = action.payload;
					state.loading = false;
				}
			)
			.addCase(
				getAllUsersInTenant.rejected,
				(state, action) => {
					state.loading = false;
					state.error = action.error as string;
				}
			);
		builder
			.addCase(
				getAllAvailableTenantThunk.pending,
				(state) => {
					state.allAvailableTenants.loading = true;
				}
			)
			.addCase(
				getAllAvailableTenantThunk.fulfilled,
				(state, action) => {
					const allTenants = Object.values(action.payload);

					state.allAvailableTenants.data = allTenants;
					state.allAvailableTenants.loading = false;
				}
			)
			.addCase(
				getAllAvailableTenantThunk.rejected,
				(state, action) => {
					state.allAvailableTenants.loading = false;
					state.allAvailableTenants.error =
						action.error as string;
				}
			);
	},
});

export const TenantActions = tenantSlice.actions;
export const TenantReducer = tenantSlice.reducer;
