import { Button } from "@mui/joy";
import { Box } from "@mui/material";
import { IoIosCheckmarkCircle } from "@react-icons/all-files/io/IoIosCheckmarkCircle";
import {
	Suspense,
	lazy,
	memo,
	useCallback,
	useContext,
	useEffect,
	useMemo,
} from "react";
import { useNavigate, useParams } from "react-router-dom";

import type { TSanitizationStep } from "../types/sanitization.type";

import { PreviewButton } from "../components/sanitize/DataRange";
import ReSanitizeConfirmModal from "../components/sanitize/ReSanitizeConfirmModal";
import { SanitizationContext } from "../contexts/sanitizationContext";
import {
	getPreferencesThunk,
	getTapeInfo,
} from "../redux/sanitization/sanitization.thunk";
import {
	useAppDispatch,
	useAppSelector,
} from "../redux/store";
import { LoaderContainer } from "../shared/Loader";

export enum StepEnum {
	TapeMeta = "tape-meta",
	Extraction = "extraction",
	Mapping = "mapping",
	Statistics = "statistics",
	Analysis = "analysis",
}

const activeSvg = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="12"
		height="12"
		viewBox="0 0 12 12"
		fill="none"
	>
		<circle
			opacity="0.2"
			cx="6"
			cy="6"
			r="6"
			fill="#F5A622"
		/>
		<circle
			cx="6"
			cy="6"
			r="4"
			fill="#F5A622"
		/>
	</svg>
);

export const stepSequence = [
	StepEnum.Extraction,
	StepEnum.Mapping,
	StepEnum.Statistics,
];

export type THandleCheckContinueProps = {
	isEditionRequired?: boolean;
	isStepConfirmed?: boolean;
	isSaveRequired?: boolean;
};

const DataRange = lazy(
	async () =>
		await import("../components/sanitize/DataRange")
);
const ColumnMapping = lazy(
	async () =>
		await import("../components/sanitize/ColumnMapping")
);
const ReviewStatistics = lazy(
	async () =>
		await import("../components/sanitize/ReviewStatistics")
);

const Step = memo(function Step({
	step,
}: {
	step: StepEnum;
}) {
	switch (step) {
		case StepEnum.Extraction:
			return (
				<Suspense fallback={<LoaderContainer />}>
					<DataRange />
				</Suspense>
			);
		case StepEnum.Mapping:
			return (
				<Suspense fallback={<LoaderContainer />}>
					<ColumnMapping />
				</Suspense>
			);
		case StepEnum.Statistics:
			return (
				<Suspense fallback={<LoaderContainer />}>
					<ReviewStatistics />
				</Suspense>
			);
		default:
			return <h1>Invalid Step</h1>;
	}
});

const SanitizeTape = () => {
	const params = useParams();
	const { tapeId, step } = params;
	const {
		setActiveTapeIdForSanitization,
		isDataRangeModified,
		isColumnMappingModified,
		handleContinue,
		loadingContinue,
		setActiveStep,
		moveToNextStep,
		disableContinue,
		isUserInputChanged,
		resetEverythingInSanitization,
		showWarningForReSanitize,
	} = useContext(SanitizationContext);

	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const {
		tapeSanitizationInfo,
		tapeSanitizationInfoStatus,
		tapeExtractionStatus,
		reviewStatisticsStatus,
		columnMappingStatus,
		// error,
	} = useAppSelector((state) => state.sanitization);

	const { activeTenant } = useAppSelector(
		(state) => state.tenant
	);

	useEffect(() => {
		if (tapeId && activeTenant) {
			setActiveTapeIdForSanitization(tapeId);
			void dispatch(getTapeInfo(tapeId));
			void dispatch(getPreferencesThunk());
		}
	}, [
		dispatch,
		setActiveTapeIdForSanitization,
		tapeId,
		activeTenant,
		resetEverythingInSanitization,
	]);

	useEffect(() => {
		setActiveStep(step as StepEnum);
	}, [setActiveStep, step]);

	const isActiveStep = useCallback(
		(key: string) => {
			return key === step ? "active" : "";
		},
		[step]
	);

	const stepChangeNotAllowed = useMemo(() => {
		return (
			tapeExtractionStatus.loading ||
			reviewStatisticsStatus.loading ||
			columnMappingStatus.loading
		);
	}, [
		columnMappingStatus.loading,
		reviewStatisticsStatus.loading,
		tapeExtractionStatus.loading,
	]);

	const handleStepChange = useCallback(
		(newStep: StepEnum) => {
			if (!stepChangeNotAllowed) {
				if (newStep === StepEnum.Analysis) {
					navigate(`/analysis/${tapeId}`);
				} else navigate(`/sanitize/${newStep}/${tapeId}`);
			}
		},
		[navigate, stepChangeNotAllowed, tapeId]
	);

	const handleCloseTape = useCallback(() => {
		navigate("/library");
	}, [navigate]);

	const isContinueActive = useMemo(() => {
		switch (step) {
			case StepEnum.Extraction:
				return isDataRangeModified;
			case StepEnum.Mapping:
				return isColumnMappingModified;
			case StepEnum.Statistics:
				return true;
			default:
				return false;
		}
	}, [step, isDataRangeModified, isColumnMappingModified]);

	const handleContinueLocal = useCallback(() => {
		if (isContinueActive) {
			void handleContinue();
		} else void moveToNextStep();
	}, [isContinueActive, moveToNextStep, handleContinue]);

	return !tapeSanitizationInfoStatus.loading &&
		activeTenant ? (
		<Box className={"sanitize-tape-page"}>
			<ReSanitizeConfirmModal />
			<Box className={"top-bar"}>
				<Box className={"tape-meta"}>
					<h2>{tapeSanitizationInfo?.tapeName}</h2>
				</Box>
				<ul
					className="actions"
					id="all-steps"
				>
					{tapeSanitizationInfo?.allSteps.map(
						(el: TSanitizationStep) => {
							return el.nextStep ? (
								<li
									key={el.path}
									id={el.path}
									className={`steps ${isActiveStep(el.path)} ${el.isCompleted ? "completed" : ""} ${
										tapeSanitizationInfo.currentStep
											.path === el.path
											? "current-step"
											: ""
									}`}
									onClick={() => {
										if (
											el.isCompleted ||
											tapeSanitizationInfo.currentStep
												.path === el.path
										) {
											handleStepChange(el.path);
										}
									}}
									style={{
										cursor:
											el.isCompleted ||
											tapeSanitizationInfo.currentStep
												.path === el.path
												? stepChangeNotAllowed
													? "progress"
													: "pointer"
												: "not-allowed",
									}}
								>
									{isActiveStep(el.path) && !el.isCompleted
										? activeSvg
										: tapeSanitizationInfo.currentStep
													.path === el.path
											? activeSvg
											: null}{" "}
									{el.isCompleted ? (
										<IoIosCheckmarkCircle
											style={{
												color: "#17B26A",
											}}
										/>
									) : null}
									<span>{el.name}</span>
								</li>
							) : null;
						}
					)}
				</ul>
			</Box>
			<Box className={"step-container"}>
				<Step step={step as StepEnum} />
			</Box>
			<Box className={"bottom-bar"}>
				<Button
					className={"close-tape"}
					onClick={handleCloseTape}
				>
					Close Tape
				</Button>
				<Box className={"right-side"}>
					<PreviewButton />
					<Button
						className={`continue-btn ${disableContinue ? "disabled" : ""}`}
						onClick={handleContinueLocal}
						disabled={disableContinue}
						loading={loadingContinue}
					>
						{isContinueActive
							? isUserInputChanged
								? "Load Selection"
								: "Save & Continue"
							: "Continue"}
					</Button>
				</Box>
			</Box>
		</Box>
	) : (
		<LoaderContainer />
	);
};

export default SanitizeTape;
