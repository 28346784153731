import {
	createAsyncThunk,
	current,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import type { TDataRangeRequestObject } from "../../types/sanitization.type";
import type { AssetClassEnum } from "../../types/tapes.type";

import { StepEnum } from "../../pages/SanitizeTape";
import { services } from "../../services";
import { formatTapeData } from "../../utils/tapeUtils";
import { TapeSliceActions } from "../tapes/tapes.slice";

import { SanitizationActions } from "./sanitization.slice";

type TUploadTapeObject = {
	file: File;
	name: string;
	assetClass: AssetClassEnum;
};

export type TEditMetaDataObject = {
	name: string;
	assetClass: AssetClassEnum;
	tapeId: string;
};

type TColumnMappingRequestObject = {
	data: Record<string, any>;
	tapeId: string;
};

export const uploadTapeThunk = createAsyncThunk(
	"sanitization/uploadTape",
	async (
		uploadTapeObject: TUploadTapeObject,
		{ dispatch, rejectWithValue }
	) => {
		try {
			const formData = new FormData();
			const meta = {
				tape_name: uploadTapeObject.name,
				signature: uploadTapeObject.assetClass,
			};
			formData.append("file", uploadTapeObject.file);
			formData.append("data", JSON.stringify(meta));
			const data =
				await services.sanitizationService.tapeUpload(
					formData
				);
			const temp = {
				name: uploadTapeObject.name,
				tapeId: data.tape_id,
			};
			const tape = formatTapeData(temp);
			dispatch(TapeSliceActions.addTape(tape));
			return data;
		} catch (error: any) {
			console.error("redux error", error);
			return rejectWithValue({
				...error.response.data,
				msg: `Error occurred: ${error.response.data.error_code} - ${error.response.data.msg}`,
			});
		}
	}
);
export const editTapeMetaDataThunk = createAsyncThunk(
	"sanitization/editTapeMetaDataThunk",
	async (
		editMetaDataObject: TEditMetaDataObject,
		{ dispatch, rejectWithValue }
	) => {
		try {
			const data =
				await services.sanitizationService.addTapeMetaData(
					editMetaDataObject
				);
			const temp = {
				name: editMetaDataObject.name,
				id: editMetaDataObject.tapeId,
			};
			dispatch(TapeSliceActions.addTape(temp));
			toast.success("Tape metadata updated successfully");
			return data;
		} catch (error: any) {
			console.error("redux error", error);
			return rejectWithValue({
				...error.response.data,
				msg: `Error occurred: ${error.response.data.error_code} - ${error.response.data.msg}`,
			});
		}
	}
);

export const getDataRangeThunk = createAsyncThunk(
	"sanitization/getDataRange",
	async (tapeId: string, { dispatch, rejectWithValue }) => {
		try {
			const data =
				await services.sanitizationService.getDataRange(
					tapeId
				);
			return data;
		} catch (error: any) {
			console.error("redux error", error);
			return rejectWithValue({
				...error.response.data,
				msg: `Error occurred: ${error.response.data.msg}`,
			});
		}
	}
);

export const getDataFromDataRange = createAsyncThunk(
	"sanitization/getColumns",
	async (
		reqObj: TDataRangeRequestObject,
		{ dispatch, rejectWithValue }
	) => {
		try {
			const data =
				await services.sanitizationService.updateDataRange(
					{
						is_step_confirmed: reqObj.isStepConfirmed,
						sheet_name: reqObj.sheet,
						header_range: reqObj.headerRange,
						body_range: reqObj.dataRange,
					},
					reqObj.tapeId
				);
			dispatch(
				SanitizationActions.updateRangeSelection({
					sheet: reqObj.sheet,
					headerRange: reqObj.headerRange,
					dataRange: reqObj.dataRange,
				})
			);
			if (reqObj.isReload) {
				toast.success("Data range preview updated.");
			}
			if (reqObj.isStepConfirmed) {
				dispatch(
					SanitizationActions.updateSanitizationSubmissionStatus(
						{
							currentStep: StepEnum.Extraction,
							submissionCreated: true,
							isSubmissionSuccessful: true,
						}
					)
				);
				return {
					table: null,
					...data,
				};
			} else {
				dispatch(
					SanitizationActions.updateSanitizationSubmissionStatus(
						{
							currentStep: StepEnum.Extraction,
							submissionCreated: false,
							isSubmissionSuccessful: false,
						}
					)
				);
			}
			return { table: data };
		} catch (error: any) {
			console.error("redux error", error);
			dispatch(
				SanitizationActions.updateSanitizationSubmissionStatus(
					{
						currentStep: StepEnum.Extraction,
						submissionCreated: true,
						isSubmissionSuccessful: false,
						error: error.response.data,
					}
				)
			);
			return rejectWithValue({
				...error.response,
				msg: `Error occurred: ${error.response.data.msg}`,
			});
		}
	}
);
export const getColumnMappingDataThunk = createAsyncThunk(
	"sanitization/getColumnMappingData",
	async (tapeId: string, { dispatch, rejectWithValue }) => {
		try {
			const data =
				await services.sanitizationService.getColumns(
					tapeId
				);
			return data;
		} catch (error: any) {
			console.error("redux error", error);
			return rejectWithValue({
				...error.response.data,
				msg: `Error occurred: ${error.response.data.error_code} - ${error.response.data.msg}`,
			});
		}
	}
);

export const updateColumnMappingDataThunk =
	createAsyncThunk(
		"sanitization/updateColumnMappingData",
		async (
			reqObj: TColumnMappingRequestObject,
			{ dispatch, rejectWithValue, getState }
		) => {
			try {
				const data =
					await services.sanitizationService.updateColumnMapping(
						reqObj.data,
						reqObj.tapeId
					);
				if (reqObj.data.is_step_confirmed) {
					dispatch(
						SanitizationActions.updateSanitizationSubmissionStatus(
							{
								currentStep: StepEnum.Mapping,
								submissionCreated: true,
								isSubmissionSuccessful: true,
							}
						)
					);
				} else {
					// const sanitizationState = (getState() as any)
					// 	.sanitization;
					// const columnMapping =
					// 	sanitizationState.columnMapping.allColumns;
					// const columnNames = Object.keys(
					// 	reqObj.data.feedback
					// ).map(
					// 	(el) => columnMapping[el].column_metadata.name
					// );

					// toast.success(
					// 	`Column mapping for ${columnNames.join()} confirmed`
					// );
					dispatch(
						SanitizationActions.updateSanitizationSubmissionStatus(
							{
								currentStep: StepEnum.Mapping,
								submissionCreated: false,
								isSubmissionSuccessful: false,
							}
						)
					);
				}
				return {
					data,
					isStepConfirmed: reqObj.data.is_step_confirmed,
				};
			} catch (error: any) {
				console.error("redux error", error);
				dispatch(
					SanitizationActions.updateSanitizationSubmissionStatus(
						{
							currentStep: StepEnum.Mapping,
							submissionCreated: true,
							isSubmissionSuccessful: false,
							error: error.response.data,
						}
					)
				);
				toast.error(
					`Error occurred: ${error.response.data.error_code} - ${error.response.data.msg}`
				);
				return rejectWithValue({
					...error.response.data,
					msg: `Error occurred: ${error.response.data.error_code} - ${error.response.data.msg}`,
				});
			}
		}
	);

export const getStatistics = createAsyncThunk(
	"sanitization/getStatistics",
	async (tapeId: string, { dispatch, rejectWithValue }) => {
		try {
			const data =
				await services.sanitizationService.getStatistics(
					tapeId
				);

			return data;
		} catch (error: any) {
			console.error("redux error", error);
			return rejectWithValue({
				...error.response.data,
				msg: `Error occurred: ${error.response.data.error_code} - ${error.response.data.msg}`,
			});
		}
	}
);

export const confirmStatistics = createAsyncThunk(
	"sanitization/confirmStatistics",
	async (tapeId: string, { dispatch, rejectWithValue }) => {
		try {
			const data =
				await services.sanitizationService.confirmStatistics(
					tapeId
				);
			dispatch(
				SanitizationActions.updateSanitizationSubmissionStatus(
					{
						currentStep: StepEnum.Statistics,
						submissionCreated: true,
						isSubmissionSuccessful: true,
					}
				)
			);

			return data;
		} catch (error: any) {
			console.error("redux error", error);
			dispatch(
				SanitizationActions.updateSanitizationSubmissionStatus(
					{
						currentStep: StepEnum.Statistics,
						submissionCreated: true,
						isSubmissionSuccessful: false,
						error: error.response.data,
					}
				)
			);
			return rejectWithValue({
				...error.response.data,
				msg: `Error occurred: ${error.response.data.error_code} - ${error.response.data.msg}`,
			});
		}
	}
);

export const getTapeInfo = createAsyncThunk(
	"sanitization/getTapeInfo",
	async (tapeId: string, { dispatch, rejectWithValue }) => {
		try {
			const data =
				await services.sanitizationService.getTapeInfo(
					tapeId
				);
			return data;
		} catch (error: any) {
			console.error("redux error", error);
			return rejectWithValue({
				...error.response.data,
				msg: `Error occurred: ${error.response.data.error_code} - ${error.response.data.msg}`,
			});
		}
	}
);

export const getPreferencesThunk = createAsyncThunk(
	"sanitization/getPreferences",
	async (_, { dispatch, rejectWithValue }) => {
		try {
			const preferences =
				await services.sanitizationService.getUserPreferences();
			return preferences;
		} catch (error: any) {
			console.error("redux error", error);
			return rejectWithValue({
				...error.response.data,
				msg: `Error occurred: ${error.response.data.error_code} - ${error.response.data.msg}`,
			});
		}
	}
);

export const postPreferencesThunk = createAsyncThunk(
	"sanitization/postPreferences",
	async (preferences: any, { rejectWithValue }) => {
		try {
			const response =
				await services.sanitizationService.postUserPreferences(
					preferences
				);
			return response;
		} catch (error: any) {
			console.error("redux error", error);
			return rejectWithValue({
				...error.response.data,
				msg: `Error occurred: ${error.response.data.error_code} - ${error.response.data.msg}`,
			});
		}
	}
);

export const getSearchMeta = createAsyncThunk(
	"sanitization/getSearchMeta",
	async (_, { rejectWithValue }) => {
		try {
			const data =
				await services.sanitizationService.getSearchMeta();
			return data;
		} catch (error: any) {
			console.error("redux error", error);
			return rejectWithValue({
				...error.response.data,
				msg: `Error occurred: ${error.response.data.error_code} - ${error.response.data.msg}`,
			});
		}
	}
);
