import axios, {
	type AxiosRequestConfig,
	type AxiosResponse,
} from "axios";

import { auth } from "../firebase/firebaseConfig";
import { getActiveTenant } from "../utils/tenantUtils";

/**
 * Represents a singleton HTTP service for making HTTP requests.
 */
export class HttpService {
	private static readonly instance: HttpService;

	/**
	 * Sends a GET request to the specified URL.
	 * @param url - The URL to send the request to.
	 * @param config - Optional configuration for the request.
	 * @returns A promise that resolves to the response data.
	 */
	async get<T>(
		url: string,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<T>> {
		return await this.request<AxiosResponse<T>>({
			url,
			method: "GET",
			...config,
		});
	}
	/**
	 * Sends a GET request to the specified URL directly without routing through request method
	 * so to use it where token is not needed.
	 * @param url - The URL to send the request to.
	 * @param config - Optional configuration for the request.
	 * @returns A promise that resolves to the response data.
	 */

	async getWithoutAuth<T>(
		url: string,
		config?: AxiosRequestConfig
	): Promise<T> {
		return await axios.get(url, config);
	}

	/**
	 * Sends a POST request to the specified URL.
	 * @param url - The URL to send the request to.
	 * @param config - Optional configuration for the request.
	 * @returns A promise that resolves to the response data.
	 */
	async post<T>(
		url: string,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<T>> {
		return await this.request<AxiosResponse<T>>({
			url,
			method: "POST",
			...config,
		});
	}

	/**
	 * Sends a PUT request to the specified URL.
	 * @param url - The URL to send the request to.
	 * @param config - Optional configuration for the request.
	 * @returns A promise that resolves to the response data.
	 */
	async put<T>(
		url: string,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<T>> {
		return await this.request<AxiosResponse<T>>({
			url,
			method: "PUT",
			...config,
		});
	}

	/**
	 * Sends a DELETE request to the specified URL.
	 * @param url - The URL to send the request to.
	 * @param config - Optional configuration for the request.
	 * @returns A promise that resolves to the response data.
	 */
	async delete<T>(
		url: string,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<T>> {
		return await this.request<AxiosResponse<T>>({
			url,
			method: "DELETE",
			...config,
		});
	}

	/**
	 * Sends an HTTP request with the specified configuration.
	 * @param config - The configuration for the request.
	 * @returns A promise that resolves to the response data.
	 */
	async request<T>(config: AxiosRequestConfig): Promise<T> {
		const token = await auth.currentUser?.getIdToken();
		const activeTenant = getActiveTenant();
		const defaultHeaders: AxiosRequestConfig["headers"] = {
			"Content-Type": "application/json;odata=verbose",
			Authorization: `Bearer ${token}`,
			"X-Tenant-ID": activeTenant ?? "",
		};

		return await axios.request({
			...config,
			headers: {
				...defaultHeaders,
				...config.headers,
			},
		});
	}
}

const httpServiceInstance = new HttpService();
export { httpServiceInstance };
