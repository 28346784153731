import type { TSimulationParameterUpdateRequestObject } from "../types/analysis.type";
import type {
	TTapeSearchByIdResponseProps,
	TTapeSearchParameters,
	TTapeSearchResponseProps,
} from "../types/tapes.type";
import type { AxiosRequestConfig } from "axios";

import { getEndpoint } from "../endpoints";

import {
	httpServiceInstance,
	type HttpService,
} from "./http.service";

/**
 * Service class for managing tenants.
 */
export class AnalysisService {
	private static readonly instance: AnalysisService;
	private static http: HttpService;
	private static endpoint: string;

	/**
	 * Private constructor to prevent instantiation.
	 * Initializes the HttpService instance.
	 */
	constructor() {
		AnalysisService.http = httpServiceInstance;
		AnalysisService.endpoint = getEndpoint();
	}

	// Search Tape APIs -----------------------------------------------------------------------------------------------

	async getTapeById(
		tapeId: string,
		config?: AxiosRequestConfig
	): Promise<any> {
		const url = `${AnalysisService.endpoint}/api/sanitization/meta/${tapeId}`;
		try {
			const { data } = await AnalysisService.http.get<any>(
				url,
				config
			);
			return data;
		} catch (error: any) {
			throw error.response;
		}
	}

	async searchTapes(
		request: TTapeSearchParameters,
		config?: AxiosRequestConfig
	): Promise<TTapeSearchResponseProps> {
		const url = `${AnalysisService.endpoint}/api/search?page=${request.page}`;
		try {
			const { data } =
				await AnalysisService.http.post<TTapeSearchResponseProps>(
					url,
					{
						data: request.form,
						...config,
					}
				);
			return data;
		} catch (error: any) {
			return {
				tapes: [],
				count: 0,
				error: error.response,
			};
		}
	}

	async searchTapeById(
		tapeId: string
	): Promise<TTapeSearchByIdResponseProps> {
		const url = `${AnalysisService.endpoint}/api/tapes/${tapeId}`;
		try {
			const { data } =
				await AnalysisService.http.get<TTapeSearchByIdResponseProps>(
					url
				);
			return data;
		} catch (error: any) {
			return {
				allowed: false,
				tape: undefined,
				message: error.response,
			};
		}
	}

	// New Analysis APIs -----------------------------------------------------------------------------------------------

	async getTapeDataForAnalysis(
		tapeId: string
	): Promise<any> {
		const url = `${AnalysisService.endpoint}/api/tapes/${tapeId}`;
		try {
			const { data } =
				await AnalysisService.http.get<any>(url);
			return data;
		} catch (error: any) {
			throw error.response;
		}
	}

	async getTapeStats(tapeId: string): Promise<any> {
		const url = `${AnalysisService.endpoint}/api/tapes/${tapeId}/stats`;
		try {
			const { data } =
				await AnalysisService.http.get<any>(url);
			return data;
		} catch (error: any) {
			throw error.response;
		}
	}

	async getPointByRunId(
		runId: string,
		tapeId: string
	): Promise<any> {
		const url = `${AnalysisService.endpoint}/api/tapes/${tapeId}/runs/${runId}`;
		const { data } =
			await AnalysisService.http.get<any>(url);
		return data;
	}

	async getPointData(
		pointId: string,
		tapeId: string,
		config?: AxiosRequestConfig
	): Promise<any> {
		try {
			const url = `${AnalysisService.endpoint}/api/points/${tapeId}/${pointId}`;
			const { data } = await AnalysisService.http.get<any>(
				url,
				{
					signal: config?.signal,
				}
			);
			return data;
		} catch (error: any) {
			throw error.response;
		}
	}

	async getPointQuestions(pointId: string): Promise<any> {
		try {
			const url = `${AnalysisService.endpoint}/api/points/${pointId}/questions`;
			const { data } =
				await AnalysisService.http.get<any>(url);
			return data;
		} catch (error: any) {
			throw error.response;
		}
	}

	async updatePointQuestions(
		requestBody: any
	): Promise<any> {
		try {
			const url = `${AnalysisService.endpoint}/api/points/${requestBody.pointId}/questions/update`;
			const { data } = await AnalysisService.http.post<any>(
				url,
				{
					data: {
						question_id: requestBody.questionId,
						user_input: requestBody.userInput,
						user_input_type: requestBody.userInputType,
						question_category_id:
							requestBody.questionCategoryId,
						is_lithe_question: requestBody.isLithe ?? false,
					},
				}
			);
			return data;
		} catch (error: any) {
			throw error.response;
		}
	}

	async confirmPointQuestions(
		pointId: string
	): Promise<any> {
		try {
			const url = `${AnalysisService.endpoint}/api/points/${pointId}/questions/confirm`;

			const { data } =
				await AnalysisService.http.post<any>(url);
			return data;
		} catch (error: any) {
			throw error.response;
		}
	}

	async resetPointData(pointId: string): Promise<any> {
		try {
			const url = `${AnalysisService.endpoint}/api/points/${pointId}/reset`;

			const { data } =
				await AnalysisService.http.get<any>(url);
			return data;
		} catch (error: any) {
			throw error.response;
		}
	}

	async getSimulationQuestions(
		runId: string
	): Promise<any> {
		const url = `${AnalysisService.endpoint}/api/runs/${runId}/simulation/questions`;
		try {
			const { data } =
				await AnalysisService.http.get<any>(url);
			return data;
		} catch (error: any) {
			throw error.response;
		}
	}

	async updateSimulationQuestions(
		requestBody: TSimulationParameterUpdateRequestObject
	): Promise<any> {
		const url = `${AnalysisService.endpoint}/api/runs/${requestBody.runId}/simulation/questions`;
		try {
			const { data } = await AnalysisService.http.post<any>(
				url,
				{
					data: {
						question_id: requestBody.questionId,
						user_input: requestBody.userInput,
						user_input_type: requestBody.userInputType,
						question_category_id:
							requestBody.questionCategoryId,
					},
				}
			);
			return data;
		} catch (error: any) {
			throw error.response;
		}
	}

	async confirmSimulationQuestions(
		runId: string
	): Promise<any> {
		const url = `${AnalysisService.endpoint}/api/runs/${runId}/simulation/questions/confirm`;
		try {
			const { data } =
				await AnalysisService.http.post<any>(url);
			return data;
		} catch (error: any) {
			throw error.response;
		}
	}

	async getSimulationResults(runId: string): Promise<any> {
		const url = `${AnalysisService.endpoint}/api/runs/${runId}/simulation/results`;
		try {
			const { data } =
				await AnalysisService.http.get<any>(url);
			return data;
		} catch (error: any) {
			throw error.response;
		}
	}
}
