import { createSlice } from "@reduxjs/toolkit";

import { type TTapeSliceStates } from "../../types/tapes.type";

const initialState: TTapeSliceStates = {
	allTapes: {},
	isLoading: false,
	error: null,
	activeTape: null,
};

export const TapeSlice = createSlice({
	name: "tapes",
	initialState,
	reducers: {
		addTape: (state, action) => {
			if (!state.allTapes[action.payload.id]) {
				state.allTapes[action.payload.id] = action.payload;
			} else {
				state.allTapes[action.payload.id] = {
					...state.allTapes[action.payload.id],
					...action.payload,
				};
			}
		},
		setActiveTape: (state, action) => {
			state.activeTape = action.payload;
		},
		updateTape: (state, action) => {
			const { id, payload } = action.payload;
			const tape = state.allTapes[id];
			const keyOutputs =
				payload.data.base_output.key_outputs;
			const assumptions =
				payload.data.base_output.assumptions;
			const loanTapeStats = payload.data.loan_tape_stats;
			const finalOutput = payload.data.final_output;
			const baseOutputGraphData =
				payload.data.base_output.graph_data;
			state.allTapes[id] = {
				...tape,
				status: payload.status,
				analysisData: {
					keyOutputs,
					assumptions,
					loanTapeStats,
					finalOutput,
					baseOutputGraphData,
				},
			};
		},
	},
	extraReducers: (builder) => {},
});

export const TapeSliceActions = TapeSlice.actions;
export const TapeReducer = TapeSlice.reducer;
