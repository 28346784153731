import { createAsyncThunk } from "@reduxjs/toolkit";

import type { TLitheCreationData } from "../../types/lithe.type";

import { services } from "../../services";

export const createLitheThunk = createAsyncThunk(
	"lithe/createLitheThunk",
	async (
		litheCreationData: TLitheCreationData,
		{ dispatch, rejectWithValue }
	) => {
		try {
			const data = await services.litheService.createLithe(
				litheCreationData
			);
			return data;
		} catch (error: any) {
			console.error("redux error", error);
			return rejectWithValue({
				...error.response.data,
				msg: `Error occurred: ${error.response.data.msg}`,
			});
		}
	}
);

export const fetchLitheThunk = createAsyncThunk(
	"lithe/fetchLitheThunk",
	async (litheId: string, { rejectWithValue }) => {
		try {
			const data =
				await services.litheService.getLithe(litheId);
			return data;
		} catch (error: any) {
			console.error("redux error", error);
			return rejectWithValue({
				...error.response.data,
				msg: `Error occurred: ${error.response.data.msg}`,
			});
		}
	}
);

export const fetchLitheSpecsQuestionsThunk =
	createAsyncThunk(
		"lithe/fetchLitheSpecsQuestionsThunk",
		async (litheId: string, { rejectWithValue }) => {
			try {
				const data =
					await services.litheService.getLitheSpecsQuestions(
						litheId
					);
				return data;
			} catch (error: any) {
				console.error("redux error", error);
				return rejectWithValue({
					...error.response.data,
					msg: `Error occurred: ${error.response.data.msg}`,
				});
			}
		}
	);

export const fetchLitheDataVizQuestionsThunk =
	createAsyncThunk(
		"lithe/fetchLitheDataVizQuestionsThunk",
		async (litheId: string, { rejectWithValue }) => {
			try {
				const data =
					await services.litheService.getLitheDataVizQuestions(
						litheId
					);
				return data;
			} catch (error: any) {
				console.error("redux error", error);
				return rejectWithValue({
					...error.response.data,
					msg: `Error occurred: ${error.response.data.msg}`,
				});
			}
		}
	);
// ---------------------------------------------------------------
// Not Required
//
// export const fetchLitheDataVizResultsThunk =
// 	createAsyncThunk(
// 		"lithe/fetchLitheDataVizResultsThunk",
// 		async (litheId: string, { rejectWithValue }) => {
// 			try {
// 				const data =
// 					await services.litheService.getLitheDataVizResults(
// 						litheId
// 					);
// 				return data;
// 			} catch (error: any) {
// 				console.error("redux error", error);
// 				return rejectWithValue({
// 					...error.response.data,
// 					msg: `Error occurred: ${error.response.data.msg}`,
// 				});
// 			}
// 		}
// 	);

export const fetchLitheCashflowVizQuestionsThunk =
	createAsyncThunk(
		"lithe/fetchLitheCashflowVizQuestionsThunk",
		async (litheId: string, { rejectWithValue }) => {
			try {
				const data =
					await services.litheService.getLitheCashflowVizQuestions(
						litheId
					);
				return data;
			} catch (error: any) {
				console.error("redux error", error);
				return rejectWithValue({
					...error.response.data,
					msg: `Error occurred: ${error.response.data.msg}`,
				});
			}
		}
	);

// ---------------------------------------------------------------
// Not Required
//
// export const fetchLitheCashflowVizResultsThunk =
// 	createAsyncThunk(
// 		"lithe/fetchLitheCashflowVizResultsThunk",
// 		async (litheId: string, { rejectWithValue }) => {
// 			try {
// 				const data =
// 					await services.litheService.getLitheDataVizResults(
// 						litheId
// 					);
// 				return data;
// 			} catch (error: any) {
// 				console.error("redux error", error);
// 				return rejectWithValue({
// 					...error.response.data,
// 					msg: `Error occurred: ${error.response.data.msg}`,
// 				});
// 			}
// 		}
// 	);

export const fetchLitheProbsQuestionsThunk =
	createAsyncThunk(
		"lithe/fetchLitheProbsQuestionsThunk",
		async (litheId: string, { rejectWithValue }) => {
			try {
				const data =
					await services.litheService.getLitheProbsQuestions(
						litheId
					);
				return data;
			} catch (error: any) {
				console.error("redux error", error);
				return rejectWithValue({
					...error.response.data,
					msg: `Error occurred: ${error.response.data.msg}`,
				});
			}
		}
	);

export const fetchLitheProbsResultsThunk = createAsyncThunk(
	"lithe/fetchLitheProbsResultsThunk",
	async (litheId: string, { rejectWithValue }) => {
		try {
			const data =
				await services.litheService.getLitheProbsResults(
					litheId
				);
			return data;
		} catch (error: any) {
			console.error("redux error", error);
			return rejectWithValue({
				...error.response.data,
				msg: `Error occurred: ${error.response.data.msg}`,
			});
		}
	}
);

export const fetchLitheApprovalInfo = createAsyncThunk(
	"lithe/fetchLitheApprovalInfo",
	async (litheId: string, { rejectWithValue }) => {
		try {
			const data =
				await services.litheService.getLitheApprovalInfo(
					litheId
				);
			return data;
		} catch (error: any) {
			console.error("redux error", error);
			return rejectWithValue({
				...error.response.data,
				msg: `Error occurred: ${error.response.data.msg}`,
			});
		}
	}
);

export const fetchLitheRFSpecsQuestionsThunk =
	createAsyncThunk(
		"lithe/fetchLitheRFSpecsQuestionsThunk",
		async (litheId: string, { rejectWithValue }) => {
			try {
				const data =
					await services.litheService.getLitheRFSpecsQuestions(
						litheId
					);
				return data;
			} catch (error: any) {
				console.error("redux error", error);
				return rejectWithValue({
					...error.response.data,
					msg: `Error occurred: ${error.response.data.msg}`,
				});
			}
		}
	);

export const fetchLitheRawProbData = createAsyncThunk(
	"lithe/fetchLitheRawProbData",
	async (litheId: string, { rejectWithValue }) => {
		try {
			const data =
				await services.litheService.getLitheRawProbData(
					litheId
				);

			return data;
		} catch (error: any) {
			console.error("redux error", error);
			return rejectWithValue({
				...error.response.data,
				msg: `Error occurred: ${error.response.data.msg}`,
			});
		}
	}
);
