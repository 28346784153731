import { createAsyncThunk } from "@reduxjs/toolkit";

import { services } from "../../services";

// This thunk is responsible for getting all the tenants for a given user.
export const getAllTenantsForUser = createAsyncThunk(
	"tenants/getAllTenantsForUser",
	async () => {
		return await services.tenantServiceInstance.getTenants();
	}
);

export const getAllUsersInTenant = createAsyncThunk(
	"tenants/getAllUsersInTenant",
	async (tenantId: string) => {
		return await services.tenantServiceInstance.getAllUsersInTenant(
			tenantId
		);
	}
);
export const getAllAvailableTenantThunk = createAsyncThunk(
	"tenants/getAllAvailableTenantThunk",
	async () => {
		return await services.tenantServiceInstance.getTenants();
	}
);
