/**
 * Component that checks the authentication status and renders different content based on the status.
 * If the user is logged in, it renders the Layout component.
 * If the authentication status is still loading, it renders a loading message.
 * If the user is not logged in, it navigates to the login page.
 */
import { Box } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { Layout } from "./components/Layout";
import { useAppSelector } from "./redux/store";
import { Loader } from "./shared/Loader";

export const PasswordSetCheck = () => {
	const {
		authStatus,
		isPasswordSet,
		isMultiFactorAuthSet,
	} = useAppSelector((state) => state.user);

	const [stage, setStage] = useState<any>(null);

	useEffect(() => {
		if (authStatus.isLoggedIn && isPasswordSet) {
			setStage("allow-login");
		} else if (authStatus.isLoggedIn && !isPasswordSet) {
			setStage("create-password");
		} else if (!authStatus.isLoggedIn) {
			setStage("login-required");
		}
	}, [authStatus, isPasswordSet, isMultiFactorAuthSet]);

	const page = useMemo(() => {
		switch (stage) {
			case "allow-login":
				return <Layout />;
			case "create-password":
				return <Navigate to="/create-password" />;
			case "mfa-required":
				return <Navigate to="/multi-factor-verification" />;
			case "login-required":
				return <Navigate to="/login" />;
			default:
				return null;
		}
	}, [stage]);

	return (
		<Box>
			{authStatus.isLoading || !stage ? (
				<Box className={"page-loader"}>
					<Loader />
				</Box>
			) : (
				page
			)}
		</Box>
	);
};
