import { Box } from "@mui/material";
import { useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { Navigation } from "./Navigation";

export const Layout = () => {
	const location = useLocation();
	const { pathname } = location;
	const showNavigation = useMemo(() => {
		if (pathname.includes("/sanitize/")) return false;
		else if (pathname.includes("/analysis/")) return false;
		else if (pathname.includes("/lithe/")) return false;
		else return true;
	}, [pathname]);

	return (
		<Box className="main-layout">
			{showNavigation ? <Navigation /> : null}
			<Box
				className="container"
				style={{
					paddingTop: showNavigation ? "60px" : "0",
				}}
			>
				<Box className="page-layout">
					<Outlet />
				</Box>
			</Box>
		</Box>
	);
};
