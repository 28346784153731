export enum GlobalRoleEnum {
	GLOBAL_ADMIN = "global_admin",
	SCOPED_USER = "scoped_user",
	GLOBAL_TENANT_ADMIN = "global_tenant_admin",
}

export type TUserAuthState = {
	token: string | null;
	decodedToken: any;
	usernameFromToken: string | null;
	authStatus: {
		isLoggedIn: boolean;
		isLoading: boolean;
	};
	isPasswordSet: boolean;
	isMultiFactorAuthSet: boolean;
	loading: boolean;
	user: any;
	isGlobalAdmin: boolean | null;
	mfaError: any;
	allUsers: {
		data: Array<any>;
		loading: boolean;
		error: any;
	};
};
