import {
	createContext,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
	type FC,
	type ReactElement,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { LitheSliceActions } from "../redux/lithe/lithe.slice";
import { fetchLitheThunk } from "../redux/lithe/lithe.thunk";
import {
	useAppDispatch,
	useAppSelector,
} from "../redux/store";
import { services } from "../services";
import { AnalysisStageEnum } from "../types/analysis.type";
import {
	LitheCreationTypeEnum,
	LitheStepEnum,
} from "../types/lithe.type";

export const LitheContext = createContext<any>(null);

const datasetlitheStepsReferenceObj = {
	[LitheStepEnum.SPECS]: {
		index: 0,
		stepId: LitheStepEnum.SPECS,
		name: "Metadata",
		isCompleted: true,
	},
	[LitheStepEnum.DATAVIZ]: {
		index: 1,
		stepId: LitheStepEnum.DATAVIZ,
		name: "Training Data",
		isCompleted: true,
	},
	[LitheStepEnum.CASHFLOWVIZ]: {
		index: 2,
		stepId: LitheStepEnum.CASHFLOWVIZ,
		name: "Risk Factor",
		isCompleted: true,
	},
	[LitheStepEnum.PROBS]: {
		index: 3,
		stepId: LitheStepEnum.PROBS,
		name: "Define Buckets",
		isCompleted: true,
	},
	[LitheStepEnum.APPROVAL]: {
		index: 4,
		stepId: LitheStepEnum.APPROVAL,
		name: "Approval",
		isCompleted: false,
	},
	[LitheStepEnum.LITHEAPPROVED]: {
		index: 5,
		stepId: LitheStepEnum.LITHEAPPROVED,
		name: "Lithe Approved",
		isCompleted: false,
	},
};

const manualLitheStepsReferenceObj = {
	[LitheStepEnum.SPECS]: {
		index: 0,
		stepId: LitheStepEnum.SPECS,
		name: "Extract Data",
		isCompleted: true,
	},
	[LitheStepEnum.RFSPECS]: {
		index: 1,
		stepId: LitheStepEnum.RFSPECS,
		name: "Risk Factor",
		isCompleted: true,
	},
	[LitheStepEnum.RAWPROBS]: {
		index: 2,
		stepId: LitheStepEnum.RAWPROBS,
		name: "Probability",
		isCompleted: true,
	},
	[LitheStepEnum.APPROVAL]: {
		index: 3,
		stepId: LitheStepEnum.APPROVAL,
		name: "Approval",
		isCompleted: false,
	},
	[LitheStepEnum.LITHEAPPROVED]: {
		index: 4,
		stepId: LitheStepEnum.LITHEAPPROVED,
		name: "Lithe Approved",
		isCompleted: false,
	},
};

const LitheStepsAccordingToMethod = {
	[LitheCreationTypeEnum.DATASET]: [
		LitheStepEnum.SPECS,
		LitheStepEnum.DATAVIZ,
		LitheStepEnum.CASHFLOWVIZ,
		LitheStepEnum.PROBS,
		LitheStepEnum.APPROVAL,
	],
	[LitheCreationTypeEnum.MANUAL]: [
		LitheStepEnum.SPECS,
		LitheStepEnum.RFSPECS,
		LitheStepEnum.RAWPROBS,
		LitheStepEnum.APPROVAL,
	],
};

export const LitheContextProvider: FC<{
	children: ReactElement;
}> = ({ children }) => {
	const { method, step, litheId } = useParams();
	const navigate = useNavigate();

	const dispatch = useAppDispatch();

	const {
		meta,
		rawProbData,
		rfSpecsQuestions,
		specsQuestions,
		dataVizResults,
		cashflowVizResults,
		probsQuestions,
		probsResults,
	} = useAppSelector((state) => state.lithe);

	const [
		continueAndConfirmLoading,
		setContinueAndConfirmLoading,
	] = useState(false);

	const allLitheSteps = useMemo(() => {
		const allSteps = meta.data?.allSteps;
		const currentStep = meta.data?.currentStep;
		if (!allSteps || !currentStep) {
			return [];
		}
		const indexOfCurrentStep =
			allSteps.indexOf(currentStep);

		const availableSteps = allSteps.filter(
			(step: LitheStepEnum) =>
				LitheStepsAccordingToMethod[
					(meta.data?.litheCreationType ??
						method) as LitheCreationTypeEnum
				].includes(step)
		);

		const referenceObj: any =
			method === "dataset"
				? datasetlitheStepsReferenceObj
				: manualLitheStepsReferenceObj;

		const steps = availableSteps.map(
			(step: LitheStepEnum, index: number) => {
				const res = {
					...referenceObj[step],
					isCurrent: false,
					isCompleted: false,
				};
				if (step === currentStep) {
					res.isCurrent = true;
				}
				if (index < indexOfCurrentStep) {
					res.isCompleted = true;
				}

				if (
					step === LitheStepEnum.APPROVAL &&
					currentStep === LitheStepEnum.LITHEAPPROVED
				) {
					res.name = "Lithe Approved";
				}

				return res;
			}
		);
		return steps;
	}, [meta, method]);

	const moveToStep = useCallback(
		(step: any) => {
			if (step.isCompleted || step.isCurrent) {
				navigate(
					`/lithe/${method}/${step.stepId}/${litheId}`
				);
			}
		},
		[litheId, method, navigate]
	);

	const moveToNextStep = useCallback(
		(step: LitheStepEnum) => {
			const list = meta.data?.allSteps;

			if (!list) return;

			const currentIndex = list.indexOf(step);
			const nextStep = list[currentIndex + 1];
			navigate(`/lithe/${method}/${nextStep}/${litheId}`);
		},
		[litheId, meta.data?.allSteps, method, navigate]
	);

	const [
		showGenerateSummaryModal,
		setShowGenerateSummaryModal,
	] = useState(false);

	const processBucketSelectionChanges =
		useCallback(() => {}, []);

	const dataVizPollingController = useRef<any>({});
	const dataVizPollingTimeout =
		useRef<NodeJS.Timeout | null>(null);
	const dataVizContinuePolling = useRef(true);

	const handleDataVizPolling = useCallback(
		async (litheId: string) => {
			if (!litheId) return;
			try {
				console.log(
					"Polling started for handleDataVizPolling",
					litheId
				);
				dispatch(
					LitheSliceActions.updateLitheDataVizResults(null)
				);
				dispatch(
					LitheSliceActions.handleDataVizResultsLoading(
						true
					)
				);
				const res =
					await services.litheService.getLitheDataVizResults(
						litheId,
						{
							signal:
								dataVizPollingController.current[litheId]
									.signal,
						}
					);
				if (res.status === AnalysisStageEnum.COMPLETED) {
					dispatch(
						LitheSliceActions.updateLitheDataVizResults(res)
					);
					dispatch(
						LitheSliceActions.handleDataVizResultsLoading(
							false
						)
					);
				}
				if (res.status === AnalysisStageEnum.ERROR) {
					dispatch(
						LitheSliceActions.updateLitheDataVizResults(
							null
						)
					);
					dispatch(
						LitheSliceActions.handleDataVizResultsLoading(
							false
						)
					);
					dispatch(
						LitheSliceActions.updateLitheDataVizResultsError(
							res.output
						)
					);
					toast.error(
						`Error filtering training data: ${
							res.output.error_msg.error_message
						}`,
						{
							autoClose: 10000,
						}
					);
					console.log("Polling stopped due to error", res);
					dataVizContinuePolling.current = false;
				} else if (
					res.status === AnalysisStageEnum.NOT_YET_STARTED
				) {
					console.log(
						"Polling stopped NOT_YET_STARTED",
						res
					);
					dispatch(
						LitheSliceActions.updateLitheDataVizResults(res)
					);
					dispatch(
						LitheSliceActions.handleDataVizResultsLoading(
							false
						)
					);
					dataVizContinuePolling.current = false;
				} else if (
					res.status !== AnalysisStageEnum.COMPLETED
				) {
					dataVizPollingTimeout.current = setTimeout(() => {
						dataVizContinuePolling.current = true;
						void handleDataVizPolling(litheId);
					}, 15000);
				} else {
					console.log("Polling stopped", res);
					dataVizContinuePolling.current = false;
				}
			} catch (error) {
				console.error("handling polling", error);
				dataVizContinuePolling.current = false;
				dispatch(
					LitheSliceActions.handleDataVizResultsLoading(
						false
					)
				);
				toast.error("Error filtering training data");
			}
		},
		[dispatch]
	);

	useEffect(() => {
		if (litheId) {
			dataVizPollingController.current[litheId] =
				new AbortController();
			return () => {
				if (dataVizPollingController.current[litheId]) {
					dataVizPollingController.current[litheId].abort();
					clearTimeout(
						dataVizPollingTimeout.current as NodeJS.Timeout
					);
				}
			};
		}
	}, [litheId]);

	const abortSteps = useCallback(() => {
		if (litheId) {
			if (
				dataVizPollingController.current[litheId] &&
				step !== LitheStepEnum.DATAVIZ
			) {
				dataVizPollingController.current[litheId].abort();
				clearTimeout(
					dataVizPollingTimeout.current as NodeJS.Timeout
				);
			}
			if (
				cashflowVizPollingController.current[litheId] &&
				step !== LitheStepEnum.CASHFLOWVIZ
			) {
				cashflowVizPollingController.current[
					litheId
				].abort();
				clearTimeout(
					cashflowVizPollingTimeout.current as NodeJS.Timeout
				);
			}
		}
	}, [litheId, step]);

	useEffect(() => {
		return () => {
			abortSteps();
		};
	}, [abortSteps, step]);

	const triggerDataVizPolling = useCallback(
		(newLitheId: string) => {
			if (litheId) {
				abortSteps();
				if (dataVizPollingController.current[litheId]) {
					dataVizPollingController.current[litheId].abort();
					clearTimeout(
						dataVizPollingTimeout.current as NodeJS.Timeout
					);
				}
				dataVizPollingController.current[newLitheId] =
					new AbortController();
				dataVizPollingTimeout.current = null;
				dataVizContinuePolling.current = true;
				void handleDataVizPolling(newLitheId);
			}
		},
		[abortSteps, handleDataVizPolling, litheId]
	);

	const cashflowVizPollingController = useRef<any>({});
	const cashflowVizPollingTimeout =
		useRef<NodeJS.Timeout | null>(null);
	const cashflowVizContinuePolling = useRef(true);

	const handleCashflowVizPolling = useCallback(
		async (litheId: string) => {
			if (!litheId) return;
			try {
				dispatch(
					LitheSliceActions.updateCashflowVizResults(null)
				);
				dispatch(
					LitheSliceActions.handleCashflowVizResultsLoading(
						true
					)
				);
				const res =
					await services.litheService.getLitheCashflowVizResults(
						litheId,
						{
							signal:
								cashflowVizPollingController.current[
									litheId
								].signal,
						}
					);
				if (res.status === AnalysisStageEnum.COMPLETED) {
					dispatch(
						LitheSliceActions.updateCashflowVizResults(res)
					);
					dispatch(
						LitheSliceActions.handleCashflowVizResultsLoading(
							false
						)
					);
				}
				if (res.status === AnalysisStageEnum.ERROR) {
					dispatch(
						LitheSliceActions.updateCashflowVizResults(null)
					);
					dispatch(
						LitheSliceActions.handleCashflowVizResultsLoading(
							false
						)
					);
					dispatch(
						LitheSliceActions.updateLitheCashflowVizResultsError(
							res.output
						)
					);
					toast.error(
						`Error filtering training data: ${
							res.output.error_msg.error_message
						}`,
						{
							autoClose: 10000,
						}
					);
					console.log("Polling stopped due to error", res);
					cashflowVizContinuePolling.current = false;
				} else if (
					res.status === AnalysisStageEnum.NOT_YET_STARTED
				) {
					console.log(
						"Polling stopped NOT_YET_STARTED",
						res
					);
					dispatch(
						LitheSliceActions.updateCashflowVizResults(res)
					);
					dispatch(
						LitheSliceActions.handleCashflowVizResultsLoading(
							false
						)
					);
					cashflowVizContinuePolling.current = false;
				} else if (
					res.status !== AnalysisStageEnum.COMPLETED
				) {
					cashflowVizPollingTimeout.current = setTimeout(
						() => {
							cashflowVizContinuePolling.current = true;
							void handleCashflowVizPolling(litheId);
						},
						15000
					);
				} else {
					console.log("Polling stopped", res);
					cashflowVizContinuePolling.current = false;
				}
			} catch (error) {
				console.error("handling polling", error);
				cashflowVizContinuePolling.current = false;
				dispatch(
					LitheSliceActions.handleCashflowVizResultsLoading(
						false
					)
				);
				toast.error("Error filtering training data");
			}
		},
		[dispatch]
	);

	useEffect(() => {
		if (litheId) {
			cashflowVizPollingController.current[litheId] =
				new AbortController();
			return () => {
				if (cashflowVizPollingController.current[litheId]) {
					cashflowVizPollingController.current[
						litheId
					].abort();
					clearTimeout(
						cashflowVizPollingTimeout.current as NodeJS.Timeout
					);
				}
			};
		}
	}, [litheId]);

	const triggerCashflowVizPolling = useCallback(
		(newLitheId: string) => {
			if (litheId) {
				abortSteps();
				if (cashflowVizPollingController.current[litheId]) {
					cashflowVizPollingController.current[
						litheId
					].abort();
					clearTimeout(
						cashflowVizPollingTimeout.current as NodeJS.Timeout
					);
				}
				cashflowVizPollingController.current[newLitheId] =
					new AbortController();
				cashflowVizPollingTimeout.current = null;
				cashflowVizContinuePolling.current = true;
				void handleCashflowVizPolling(newLitheId);
			}
		},
		[abortSteps, handleCashflowVizPolling, litheId]
	);

	const probsPollingController = useRef<any>({});
	const probsPollingTimeout = useRef<NodeJS.Timeout | null>(
		null
	);
	const probsContinuePolling = useRef(true);

	const handleProbsPolling = useCallback(
		async (litheId: string) => {
			if (!litheId) return;
			try {
				console.log(
					"Polling started for handleProbsPolling",
					litheId
				);
				dispatch(
					LitheSliceActions.updateProbsResults(null)
				);
				dispatch(
					LitheSliceActions.handleProbsResultsLoading(true)
				);
				const res =
					await services.litheService.getLitheProbsResults(
						litheId,
						{
							signal:
								probsPollingController.current[litheId]
									.signal,
						}
					);
				if (res.status === AnalysisStageEnum.COMPLETED) {
					dispatch(
						LitheSliceActions.updateProbsResults(res)
					);
					dispatch(
						LitheSliceActions.handleProbsResultsLoading(
							false
						)
					);
				}
				if (res.status === AnalysisStageEnum.ERROR) {
					dispatch(
						LitheSliceActions.updateProbsResults(null)
					);
					dispatch(
						LitheSliceActions.handleProbsResultsLoading(
							false
						)
					);
					dispatch(
						LitheSliceActions.updateProbsResultsError(
							res.output
						)
					);
					toast.error(
						`Error generating probabilities: ${
							res.output.error_msg.error_message
						}`,
						{
							autoClose: 10000,
						}
					);
					console.log("Polling stopped due to error", res);
					probsContinuePolling.current = false;
				} else if (
					res.status !== AnalysisStageEnum.COMPLETED
				) {
					probsPollingTimeout.current = setTimeout(() => {
						probsContinuePolling.current = true;
						void handleProbsPolling(litheId);
					}, 15000);
				} else {
					console.log("Polling stopped", res);
					probsContinuePolling.current = false;
				}
			} catch (error) {
				console.error("handling polling", error);
				probsContinuePolling.current = false;
				dispatch(
					LitheSliceActions.handleProbsResultsLoading(false)
				);
				toast.error("Error generating probabilities");
			}
		},
		[dispatch]
	);

	useEffect(() => {
		if (litheId) {
			probsPollingController.current[litheId] =
				new AbortController();
			return () => {
				if (probsPollingController.current[litheId]) {
					probsPollingController.current[litheId].abort();
					clearTimeout(
						probsPollingTimeout.current as NodeJS.Timeout
					);
				}
			};
		}
	}, [litheId]);

	const triggerProbsPolling = useCallback(
		(newLitheId: string) => {
			if (litheId) {
				abortSteps();
				if (probsPollingController.current[litheId]) {
					probsPollingController.current[litheId].abort();
					clearTimeout(
						probsPollingTimeout.current as NodeJS.Timeout
					);
				}
				probsPollingController.current[newLitheId] =
					new AbortController();
				probsPollingTimeout.current = null;
				probsContinuePolling.current = true;
				void handleProbsPolling(newLitheId);
			}
		},
		[abortSteps, handleProbsPolling, litheId]
	);

	const processDataVizChanges = useCallback(async () => {
		try {
			if (!litheId) return;
			toast.success("Data Filtering started");
			dispatch(
				LitheSliceActions.handleDataVizResultsLoading(true)
			);
			const data =
				await services.litheService.processDataViz(litheId);

			if (data.is_success) {
				triggerDataVizPolling(litheId);
			} else {
				toast.error("Error processing training data");
				dispatch(
					LitheSliceActions.handleDataVizResultsLoading(
						false
					)
				);
			}
		} catch (error) {
			console.error(error);
			toast.error("Error processing training data");
			dispatch(
				LitheSliceActions.handleDataVizResultsLoading(false)
			);
		}
	}, [dispatch, litheId, triggerDataVizPolling]);

	const processCashflowVizChanges =
		useCallback(async () => {
			try {
				if (!litheId) return;
				toast.success("Data Filtering started");
				dispatch(
					LitheSliceActions.handleCashflowVizResultsLoading(
						true
					)
				);
				const data =
					await services.litheService.processCashflowViz(
						litheId
					);

				if (data.is_success) {
					triggerCashflowVizPolling(litheId);
				} else {
					toast.error("Error processing risk factor");
					dispatch(
						LitheSliceActions.handleCashflowVizResultsLoading(
							false
						)
					);
				}
			} catch (error) {
				console.error(error);
				toast.error("Error processing risk factor");
				dispatch(
					LitheSliceActions.handleCashflowVizResultsLoading(
						false
					)
				);
			}
		}, [dispatch, litheId, triggerCashflowVizPolling]);

	const processProbsChanges = useCallback(async () => {
		try {
			if (!litheId) return;
			toast.success("Probabilities generation started");
			const data =
				await services.litheService.confirmProbs(litheId);

			if (data.is_success) {
				return {
					moveToNextStep: true,
				};
			} else {
				return {
					moveToNextStep: false,
				};
			}
		} catch (error) {
			console.error(error);
			toast.error("Error processing buckets");
			return {
				moveToNextStep: false,
			};
		}
	}, [litheId]);

	const processRawProbsChanges = useCallback(async () => {
		try {
			if (!litheId) return;
			toast.success("Probabilities generation started");
			const data =
				await services.litheService.processRawProbs(
					litheId
				);

			if (data.is_success) {
				// Return success from here then call meta and then move to next
				return {
					moveToNextStep: true,
				};
			} else {
				return {
					moveToNextStep: false,
				};
			}
		} catch (error) {
			console.error(error);
			toast.error("Error processing buckets");
			return {
				moveToNextStep: false,
			};
		}
	}, [litheId]);

	const confirmSpecsChanges = useCallback(async () => {
		try {
			if (!litheId) return;
			toast.success("Metadata confirmed");
			const data =
				await services.litheService.confirmSpecs(litheId);

			if (data.is_success) {
				// Return success from here then call meta and then move to next
				return {
					moveToNextStep: true,
				};
			} else {
				return {
					moveToNextStep: false,
				};
			}
		} catch (error) {
			console.error(error);
			toast.error("Error confirming metadata");
			return {
				moveToNextStep: false,
			};
		}
	}, [litheId]);

	const confirmDataVizChanges = useCallback(async () => {
		try {
			if (!litheId) return;
			const data =
				await services.litheService.confirmDataViz(litheId);

			if (data.is_success) {
				// Return success from here then call meta and then move to next
				toast.success(
					"Training Data confirmed successfully"
				);
				return {
					moveToNextStep: true,
				};
			} else {
				return {
					moveToNextStep: false,
				};
			}
		} catch (error) {
			console.error(error);
			toast.error("Error confirming training data");
			return {
				moveToNextStep: false,
			};
		}
	}, [litheId]);

	const confirmCashflowVizChanges =
		useCallback(async () => {
			try {
				if (!litheId) return;
				const data =
					await services.litheService.confirmCashflowViz(
						litheId
					);

				if (data.is_success) {
					// Return success from here then call meta and then move to next
					toast.success(
						"Risk Factor confirmed successfully"
					);
					return {
						moveToNextStep: true,
					};
				} else {
					return {
						moveToNextStep: false,
					};
				}
			} catch (error) {
				console.error(error);
				toast.error("Error confirming risk factor");
				return {
					moveToNextStep: false,
				};
			}
		}, [litheId]);

	const confirmBucketSelectionChanges =
		useCallback(async () => {
			try {
				if (!litheId) return;
				const data =
					await services.litheService.confirmProbs(litheId);
				if (data.is_success) {
					// Return success from here then call meta and then move to next
					toast.success("Buckets confirmed successfully");
					return {
						moveToNextStep: true,
					};
				} else {
					return {
						moveToNextStep: false,
					};
				}
			} catch (error) {
				console.error(error);
				toast.error("Error confirming buckets");
				return {
					moveToNextStep: false,
				};
			}
		}, [litheId]);

	const confirmApprovalChanges = useCallback(async () => {
		try {
			if (!litheId) return;
			const data =
				await services.litheService.confirmLitheApprovalInfo(
					litheId
				);

			if (data.is_success) {
				toast.success("Approval confirmed successfully");
				return {
					moveToNextStep: true,
				};
			} else {
				return {
					moveToNextStep: false,
				};
			}
		} catch (error) {
			console.error(error);
			toast.error("Error confirming approval");
			return {
				moveToNextStep: false,
			};
		}
	}, [litheId]);

	const confirmLitheRFSpecsChanges =
		useCallback(async () => {
			try {
				if (!litheId) return;
				const data =
					await services.litheService.confirmLitheRFSpecs(
						litheId
					);

				if (data.is_success) {
					toast.success(
						"Risk Factors confirmed successfully"
					);
					// Return success from here then call meta and then move to next
					return {
						moveToNextStep: true,
					};
				} else {
					return {
						moveToNextStep: false,
					};
				}
			} catch (error) {
				console.error(error);
				toast.error("Error confirming risk factors");
				return {
					moveToNextStep: false,
				};
			}
		}, [litheId]);

	const continueAndConfirmQuestions =
		useCallback(async () => {
			setContinueAndConfirmLoading(true);
			let res = {
				moveToNextStep: false,
			};
			switch (step) {
				case LitheStepEnum.SPECS: {
					res = (await confirmSpecsChanges()) ?? res;
					break;
				}
				case LitheStepEnum.DATAVIZ: {
					res = (await confirmDataVizChanges()) ?? res;
					break;
				}
				case LitheStepEnum.CASHFLOWVIZ: {
					res = (await confirmCashflowVizChanges()) ?? res;
					break;
				}
				case LitheStepEnum.PROBS: {
					res = (await processProbsChanges()) ?? res;
					break;
				}
				case LitheStepEnum.APPROVAL: {
					res = (await confirmApprovalChanges()) ?? res;
					break;
				}
				case LitheStepEnum.RFSPECS: {
					res = (await confirmLitheRFSpecsChanges()) ?? res;
					break;
				}
				case LitheStepEnum.RAWPROBS: {
					res = (await processRawProbsChanges()) ?? res;
					break;
				}
				default:
					break;
			}
			if (
				litheId &&
				step &&
				step !== LitheStepEnum.LITHEAPPROVED
			) {
				await dispatch(fetchLitheThunk(litheId));
				if (res.moveToNextStep) {
					moveToNextStep(step as LitheStepEnum);
				} else {
					setContinueAndConfirmLoading(false);
				}
			}
		}, [
			step,
			litheId,
			confirmSpecsChanges,
			confirmDataVizChanges,
			confirmCashflowVizChanges,
			processProbsChanges,
			confirmApprovalChanges,
			confirmLitheRFSpecsChanges,
			processRawProbsChanges,
			dispatch,
			moveToNextStep,
		]);

	const runAnalysis = useCallback(() => {
		switch (step) {
			case LitheStepEnum.SPECS: {
				break;
			}
			case LitheStepEnum.DATAVIZ: {
				void processDataVizChanges();
				break;
			}
			case LitheStepEnum.CASHFLOWVIZ: {
				void processCashflowVizChanges();
				break;
			}
			case LitheStepEnum.PROBS: {
				processBucketSelectionChanges();
				break;
			}
			default:
				break;
		}
	}, [
		step,
		processDataVizChanges,
		processCashflowVizChanges,
		processBucketSelectionChanges,
	]);

	const resetLitheAllStateAndPolling = useCallback(() => {
		dispatch(LitheSliceActions.resetLitheState());
		dataVizPollingController.current = {};
		cashflowVizPollingController.current = {};
		if (dataVizPollingTimeout.current) {
			clearTimeout(dataVizPollingTimeout.current);
			dataVizPollingTimeout.current = null;
		}
		if (cashflowVizPollingTimeout.current) {
			clearTimeout(cashflowVizPollingTimeout.current);
			cashflowVizPollingTimeout.current = null;
		}
		dataVizContinuePolling.current = false;
		cashflowVizContinuePolling.current = false;
	}, [dispatch]);

	const disableContinue = useMemo(() => {
		if (
			meta.data?.currentStep === LitheStepEnum.LITHEAPPROVED
		) {
			return true;
		}
		switch (step) {
			case LitheStepEnum.SPECS:
				return false;
			case LitheStepEnum.DATAVIZ:
				return false;
			case LitheStepEnum.CASHFLOWVIZ:
				return false;
			case LitheStepEnum.PROBS:
				return false;
			case LitheStepEnum.RFSPECS:
				return false;
			case LitheStepEnum.RAWPROBS:
				if (rawProbData.data?.is_confirmed) {
					return false;
				}
				return true;
			case LitheStepEnum.APPROVAL:
				return false;
			case LitheStepEnum.LITHEAPPROVED:
				return true;
			default:
				return true;
		}
	}, [
		meta.data?.currentStep,
		rawProbData.data?.is_confirmed,
		step,
	]);

	const shouldDisableEverything = useMemo(() => {
		if (
			meta.data?.currentStep === LitheStepEnum.LITHEAPPROVED
		) {
			return true;
		} else if (meta.data === null) {
			return true;
		}
		return false;
	}, [meta.data]);

	useEffect(() => {
		switch (step) {
			case LitheStepEnum.SPECS:
				setContinueAndConfirmLoading(
					specsQuestions.loading
				);
				break;
			case LitheStepEnum.DATAVIZ:
				setContinueAndConfirmLoading(
					dataVizResults.loading
				);
				break;
			case LitheStepEnum.CASHFLOWVIZ:
				setContinueAndConfirmLoading(
					cashflowVizResults.loading
				);
				break;
			case LitheStepEnum.PROBS:
				setContinueAndConfirmLoading(
					probsQuestions.loading
				);
				break;
			case LitheStepEnum.RFSPECS:
				setContinueAndConfirmLoading(
					rfSpecsQuestions.loading
				);
				break;
			case LitheStepEnum.RAWPROBS:
				setContinueAndConfirmLoading(rawProbData.loading);
				break;
			case LitheStepEnum.APPROVAL:
				setContinueAndConfirmLoading(probsResults.loading);
				break;
			case LitheStepEnum.LITHEAPPROVED:
				break;
			default:
				break;
		}
	}, [
		cashflowVizResults.loading,
		dataVizResults.loading,
		dispatch,
		litheId,
		probsQuestions.loading,
		probsResults.loading,
		rawProbData.loading,
		rfSpecsQuestions.loading,
		specsQuestions.loading,
		step,
	]);

	return (
		<LitheContext.Provider
			value={{
				method,
				step,
				litheId,
				allLitheSteps,
				runAnalysis,
				moveToStep,
				triggerDataVizPolling,
				triggerCashflowVizPolling,
				triggerProbsPolling,
				continueAndConfirmQuestions,
				resetLitheAllStateAndPolling,
				showGenerateSummaryModal,
				setShowGenerateSummaryModal,
				confirmBucketSelectionChanges,
				disableContinue,
				shouldDisableEverything,
				continueAndConfirmLoading,
				setContinueAndConfirmLoading,
			}}
		>
			{children}
		</LitheContext.Provider>
	);
};
