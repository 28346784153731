export const getEndpoint = () => {
	const frontendUrl = window.location.origin;
	if (frontendUrl.includes("localhost")) {
		return "http://localhost:8080";
	} else if (frontendUrl.includes("lab.alloan.ai")) {
		return "https://lab.alloan.ai";
	} else {
		return "https://app.alloan.ai";
	}
};
