import { createAsyncThunk } from "@reduxjs/toolkit";

import { services } from "../../services";

export const getTapeDataForAnalysisThunk = createAsyncThunk(
	"analysis/getTapeDataForAnalysisThunk",
	async (tapeId: string, { dispatch, rejectWithValue }) => {
		try {
			const data =
				await services.analysisServiceInstance.getTapeDataForAnalysis(
					tapeId
				);
			return data;
		} catch (error: any) {
			console.error("redux error", error);
			return rejectWithValue({
				...error.response.data,
				msg: `Error occurred: ${error.response.data.msg}`,
			});
		}
	}
);

export const getTapeStatsThunk = createAsyncThunk(
	"analysis/getTapeStatsThunk",
	async (tapeId: string) => {
		const data =
			await services.analysisServiceInstance.getTapeStats(
				tapeId
			);
		return data;
	}
);

export const getPointByRunIdThunk = createAsyncThunk(
	"analysis/getPointByRunIdThunk",
	async ({
		runId,
		tapeId,
	}: {
		runId: string;
		tapeId: string;
	}) => {
		const data =
			await services.analysisServiceInstance.getPointByRunId(
				runId,
				tapeId
			);
		return data;
	}
);

export const getQuestionsForAnalysisThunk =
	createAsyncThunk(
		"analysis/getQuestionsForAnalysisThunk",
		async (pointId: string) => {
			const data =
				await services.analysisServiceInstance.getPointQuestions(
					pointId
				);
			return data;
		}
	);

export const getSimulationQuestionsThunk = createAsyncThunk(
	"analysis/getSimulationQuestionsThunk",
	async (runId: string) => {
		const data =
			await services.analysisServiceInstance.getSimulationQuestions(
				runId
			);
		return data;
	}
);
