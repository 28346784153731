import { createSlice } from "@reduxjs/toolkit";

import { parameters } from "../../components/analysis/GlobalParameters";
import { type TAnalysisState } from "../../types/analysis.type";
import {
	extractLitheQuestions,
	extractQuestions,
} from "../../utils/analysisUtils";

import {
	getPointByRunIdThunk,
	getQuestionsForAnalysisThunk,
	getSimulationQuestionsThunk,
	getTapeDataForAnalysisThunk,
	getTapeStatsThunk,
} from "./analysis.thunk";

const initialState: TAnalysisState = {
	tapeStats: {
		data: null,
		loading: false,
		error: null,
	},
	tapeData: {
		data: null,
		loading: false,
		error: null,
	},
	valuationRun: {
		data: [],
		loading: false,
		error: null,
	},
	allPointData: {
		data: null,
		loading: false,
		error: null,
	},
	pointData: {
		data: null,
		loading: false,
		error: null,
	},
	jsonParameters: {
		data: null,
		loading: false,
		error: null,
	},
	simulationParameters: {
		data: null,
		loading: false,
		error: null,
	},
};

/**
 * Redux slice for managing analysis state.
 */
export const analysisState = createSlice({
	name: "analysis",
	initialState,
	reducers: {
		updatePointData: (state, action) => {
			state.pointData = {
				data: action.payload,
				loading: false,
				error: null,
			};
		},
		togglePointDataLoading: (state, action) => {
			state.pointData = {
				...state.pointData,
				loading: action.payload,
			};
		},
		updateParameter: (state, action) => {
			const data = action.payload;

			const globalParameters = extractQuestions(
				data.global.normal_questions
			);

			const actions = data.actions.map(
				(actionData: any) => {
					const parameters = extractQuestions(
						actionData.normal_questions
					);
					let litheData = null;

					if (actionData.lithe_question) {
						litheData = extractLitheQuestions(
							actionData.lithe_question,
							actionData.meta.action_id
						);
					}

					return {
						parameters,
						meta: {
							displayName: actionData.meta.display_name,
							actionId: actionData.meta.action_id,
						},
						litheData,
					};
				}
			);

			state.jsonParameters = {
				data: {
					globalParameters,
					actions,
					isConfirmed: data.is_confirmed,
				},
				loading: false,
				error: null,
			};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(
				getTapeDataForAnalysisThunk.pending,
				(state) => {
					state.tapeData = {
						data: null,
						loading: true,
						error: null,
					};
					state.valuationRun = {
						data: [],
						loading: true,
						error: null,
					};
				}
			)
			.addCase(
				getTapeDataForAnalysisThunk.fulfilled,
				(state, action) => {
					state.tapeData = {
						data: action.payload.tape,
						loading: false,
						error: null,
					};
					state.valuationRun = {
						data: action.payload.tape.valuation_runs,
						loading: false,
						error: null,
					};
				}
			)
			.addCase(
				getTapeDataForAnalysisThunk.rejected,
				(state, action) => {
					state.tapeData = {
						data: null,
						loading: false,
						error: action.payload,
					};
					state.valuationRun = {
						data: [],
						loading: false,
						error: action.payload,
					};
				}
			);

		builder
			.addCase(getPointByRunIdThunk.pending, (state) => {
				state.allPointData = {
					data: null,
					loading: true,
					error: null,
				};
			})
			.addCase(
				getPointByRunIdThunk.fulfilled,
				(state, action) => {
					state.allPointData = {
						data: action.payload,
						loading: false,
						error: null,
					};
				}
			)
			.addCase(
				getPointByRunIdThunk.rejected,
				(state, action) => {
					state.allPointData = {
						data: null,
						loading: false,
						error: action.payload,
					};
				}
			);
		builder
			.addCase(getTapeStatsThunk.pending, (state) => {
				state.tapeStats = {
					data: null,
					loading: true,
					error: null,
				};
			})
			.addCase(
				getTapeStatsThunk.fulfilled,
				(state, action) => {
					state.tapeStats = {
						data: action.payload,
						loading: false,
						error: null,
					};
				}
			)
			.addCase(
				getTapeStatsThunk.rejected,
				(state, action) => {
					state.tapeStats = {
						data: null,
						loading: false,
						error: action.payload,
					};
				}
			);
		builder
			.addCase(
				getQuestionsForAnalysisThunk.pending,
				(state) => {
					state.jsonParameters = {
						data: null,
						loading: true,
						error: null,
					};
				}
			)
			.addCase(
				getQuestionsForAnalysisThunk.fulfilled,
				(state, action) => {
					const data = action.payload;

					const globalParameters = extractQuestions(
						data.global.normal_questions
					);

					const actions = data.actions.map(
						(actionData: any) => {
							const parameters = extractQuestions(
								actionData.normal_questions
							);
							let litheData = null;

							if (actionData.lithe_question) {
								litheData = extractLitheQuestions(
									actionData.lithe_question,
									actionData.meta.action_id
								);
							}

							return {
								parameters,
								meta: {
									displayName: actionData.meta.display_name,
									actionId: actionData.meta.action_id,
								},
								litheData,
							};
						}
					);

					state.jsonParameters = {
						data: {
							globalParameters,
							actions,
							isConfirmed: data.is_confirmed,
						},
						loading: false,
						error: null,
					};
				}
			)
			.addCase(
				getQuestionsForAnalysisThunk.rejected,
				(state, action) => {
					state.jsonParameters = {
						data: null,
						loading: false,
						error: action.payload,
					};
				}
			);
		builder
			.addCase(
				getSimulationQuestionsThunk.pending,
				(state) => {
					state.simulationParameters = {
						data: null,
						loading: true,
						error: null,
					};
				}
			)
			.addCase(
				getSimulationQuestionsThunk.fulfilled,
				(state, action) => {
					const data = action.payload.questions;
					const parameters = extractQuestions(data);
					state.simulationParameters = {
						data: {
							parameters,
							isConfirmed: action.payload.is_confirmed,
						},
						loading: false,
						error: null,
					};
				}
			)
			.addCase(
				getSimulationQuestionsThunk.rejected,
				(state, action) => {
					state.simulationParameters = {
						data: null,
						loading: false,
						error: action.payload,
					};
				}
			);
	},
});

export const AnalysisActions = analysisState.actions;
export const AnalysisReducer = analysisState.reducer;
