import React, {
	createContext,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
	type ReactElement,
} from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { AnalysisActions } from "../redux/analysis/analysis.slice";
import {
	getPointByRunIdThunk,
	getQuestionsForAnalysisThunk,
} from "../redux/analysis/analysis.thunk";
import {
	useAppDispatch,
	useAppSelector,
} from "../redux/store";
import { services } from "../services";
import { AnalysisStageEnum } from "../types/analysis.type";
import { hashUserIdToColor } from "../utils/tenantUtils";

export const AnalysisContext = createContext<any>(null);

export const AnalysisContextProvider: React.FC<{
	children: ReactElement;
}> = ({ children }) => {
	const dispatch = useAppDispatch();
	const { valuationRun, allPointData, pointData } =
		useAppSelector((state) => state.analysis);

	const { user } = useAppSelector((state) => state.user);

	const { tapeId } = useParams<{ tapeId: string }>();
	const [selectedRun, setSelectedRun] = useState<Record<
		string,
		any
	> | null>(null);

	const [selectedUID, setSelectedUID] = useState<
		string | null
	>(null);

	const [selectedUser, setSelectedUser] =
		useState<any>(null);

	const [isRunEditable, setIsRunEditable] = useState(false);

	useEffect(() => {
		if (selectedUser) {
			setIsRunEditable(selectedUser.is_editable);
		} else {
			setIsRunEditable(false);
		}
	}, [selectedUser]);

	const [selectedPoint, setSelectedPoint] =
		useState<any>(null);

	const controller = useRef<any>({});
	const timeout = useRef<NodeJS.Timeout | null>(null);
	const continuePolling = useRef(true);

	const resetEverything = useCallback(() => {
		Object.keys(controller.current).forEach((key) => {
			controller.current[key].abort();
		});
		if (timeout.current) {
			clearTimeout(timeout.current);
		}
		controller.current = {};
		timeout.current = null;
		continuePolling.current = false;
	}, []);

	useEffect(() => {
		return () => {
			resetEverything();
		};
	}, [resetEverything]);

	useEffect(() => {
		if (selectedRun && tapeId) {
			resetEverything();
			void dispatch(
				getPointByRunIdThunk({
					runId: selectedRun.runId,
					tapeId,
				})
			);
			setSelectedPoint(null);
		}
	}, [dispatch, resetEverything, selectedRun, tapeId]);

	const [
		isEditMaestroDrawerOpen,
		setIsEditMaestroDrawerOpen,
	] = useState(false);

	const handlePolling = useCallback(
		async (pointId: string) => {
			console.log(
				"Polling test",
				pointId,
				tapeId,
				controller.current
			);
			if (!pointId || !tapeId) return;
			try {
				dispatch(
					AnalysisActions.togglePointDataLoading(true)
				);
				dispatch(AnalysisActions.updatePointData(null));
				const res =
					await services.analysisServiceInstance.getPointData(
						pointId,
						tapeId,
						{
							signal: controller.current[pointId].signal,
						}
					);
				if (res.status === AnalysisStageEnum.COMPLETED) {
					dispatch(AnalysisActions.updatePointData(res));
				}
				if (res.status !== AnalysisStageEnum.COMPLETED) {
					timeout.current = setTimeout(() => {
						continuePolling.current = true;
						void handlePolling(pointId);
					}, 5000);
				} else {
					console.log("Polling stopped", res);
					continuePolling.current = false;
				}
			} catch (error) {
				console.error("handling polling", error);
				continuePolling.current = false;
				dispatch(
					AnalysisActions.togglePointDataLoading(true)
				);
			}
		},
		[tapeId, dispatch, controller]
	);

	const handlePointSelection = useCallback(
		(point: any) => {
			if (tapeId) {
				resetEverything();
				const pointId = point.pointId;
				controller.current[pointId] = new AbortController();
				setSelectedPoint(point);
				void handlePolling(pointId);
			}
		},
		[tapeId, resetEverything, handlePolling]
	);

	const reloadPointData = useCallback(
		(pointId: string) => {
			if (selectedPoint?.pointId === pointId) {
				if (controller.current[pointId]) {
					controller.current[pointId].abort();
					clearTimeout(timeout.current as NodeJS.Timeout);
				}
				controller.current[pointId] = new AbortController();
				void handlePolling(pointId);
				void dispatch(
					getQuestionsForAnalysisThunk(
						selectedPoint.pointId
					)
				);
			}
		},
		[dispatch, handlePolling, selectedPoint?.pointId]
	);

	useEffect(() => {
		if (selectedPoint) {
			void dispatch(
				getQuestionsForAnalysisThunk(selectedPoint.pointId)
			);
		}
	}, [dispatch, resetEverything, selectedPoint]);

	const resetPointData = useCallback(async () => {
		if (selectedPoint?.pointId) {
			try {
				const data =
					await services.analysisServiceInstance.resetPointData(
						selectedPoint.pointId
					);
				if (data.is_success) {
					toast.success("Point data reset successfully");
					reloadPointData(selectedPoint.pointId);
				} else {
					toast.error(data.error_msg);
				}
			} catch (error) {
				console.error("resetting point data", error);
			}
		}
	}, [reloadPointData, selectedPoint?.pointId]);

	const [selectedAction, setSelectedAction] = useState<{
		label: string;
		value: string;
	} | null>(null);

	const actionOptions = useMemo(() => {
		if (pointData.data?.graphs.actions) {
			const temp = pointData.data?.graphs.actions;
			const res = temp.map(
				(action: {
					action_id: string;
					action_name: string;
					probs: Array<number>;
				}) => {
					return {
						actionId: action.action_id,
						actionName: action.action_name,
						data: action.probs,
					};
				}
			);
			return res;
		} else return [];
	}, [pointData.data?.graphs.actions]);

	useEffect(() => {
		if (actionOptions.length > 0 && !selectedAction) {
			setSelectedAction({
				label: actionOptions[0].actionName,
				value: actionOptions[0].actionId,
			});
		}
	}, [actionOptions, selectedAction]);

	const [modelOptions, setModelOptions] = useState<
		Array<any>
	>([]);

	const [userList, setUserList] = useState<Array<any>>([]);

	useEffect(() => {
		if (!user?.uid) return;
		const uid = selectedUser
			? selectedUser.initiated_by_uid
			: user?.uid;

		const userList: Array<any> = [];
		const runs = valuationRun.data.map((run) => {
			const obj = {
				runName: run.run_data.run_type_name,
				runId: "",
				runCombinationId: run.run_data.run_combination_id,
			};

			userList.push({
				runCombinationId: run.run_data.run_combination_id,
				users: run.users.map((el: any) => {
					const name = el.run_name.split(" ");
					return {
						...el,
						tapeUserIcon:
							name.length > 1
								? name[0][0] + name[1][0]
								: name[0][0],
						tapeUserIconColor: hashUserIdToColor(
							el.initiated_by_uid
						),
					};
				}),
			});

			const allowDefaultRun = selectedUser
				? selectedUser.is_default_run
				: false;

			const currentUser = run.users.find(
				(userObj: any) =>
					userObj.initiated_by_uid === uid &&
					userObj.is_default_run === allowDefaultRun
			);

			obj.runId = currentUser?.run_id || "";

			return obj;
		});
		setModelOptions(runs);
		setSelectedRun(runs[0]);
		setSelectedUID(uid);
		setUserList(userList);
	}, [valuationRun, user, selectedUser]);

	const [
		isSimulationDrawerOpen,
		setIsSimulationDrawerOpen,
	] = useState(false);

	const value = {
		tapeId,
		selectedRun,
		setSelectedRun,
		selectedUID,
		setSelectedUID,
		selectedPoint,
		setSelectedPoint,
		modelOptions,
		userList,
		selectedUser,
		setSelectedUser,
		handlePointSelection,
		actionOptions,
		selectedAction,
		setSelectedAction,
		isEditMaestroDrawerOpen,
		setIsEditMaestroDrawerOpen,
		resetPointData,
		isRunEditable,
		reloadPointData,
		isSimulationDrawerOpen,
		setIsSimulationDrawerOpen,
	};
	return (
		<AnalysisContext.Provider value={value}>
			{children}
		</AnalysisContext.Provider>
	);
};
