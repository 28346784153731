import { Box } from "@mui/material";
import {
	type User,
	multiFactor,
	onAuthStateChanged,
} from "firebase/auth";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { AppRouter } from "./AppRouter";
import { auth } from "./firebase/firebaseConfig";
import {
	useAppDispatch,
	useAppSelector,
} from "./redux/store";
import { getAllTenantsForUser } from "./redux/tenants/tenant.thunk";
import { UserAuthActions } from "./redux/user/user.slice";
import { userLoginThunk } from "./redux/user/user.thunk";
import { Loader } from "./shared/Loader";

import "react-toastify/dist/ReactToastify.css";

function App() {
	// Initialize dispatch and navigate hooks
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	// Initialize loading state
	const [loading, setLoading] = useState(true);

	// Get authStatus and isPasswordSet from Redux store
	const { authStatus, isPasswordSet, token } =
		useAppSelector((state) => state.user);

	const { activeTenant } = useAppSelector(
		(state) => state.tenant
	);

	// Define a callback function to login the Firebase user
	const loginFirebaseUser = useCallback(
		async (user: User) => {
			const token = await user.getIdToken();
			dispatch(UserAuthActions.setAuthenticated(token));
			dispatch(UserAuthActions.setMfaError(null));
		},
		[dispatch]
	);

	const initialSetup = useCallback(
		async (token: string) => {
			if (token) {
				await Promise.all([
					dispatch(getAllTenantsForUser()),
					dispatch(userLoginThunk()),
				]);
			}
		},
		[dispatch]
	);

	useEffect(() => {
		if (token) {
			void initialSetup(token);
		}
	}, [token, initialSetup]);

	// Define a callback function to get the active user session
	const getActiveSession = useCallback(async () => {
		try {
			setLoading(true);

			void dispatch(UserAuthActions.setAuthLoading(true));

			onAuthStateChanged(auth, async (user) => {
				if (user) {
					if (!user.emailVerified) {
						await loginFirebaseUser(user);
						navigate("/create-password");
						void dispatch(
							UserAuthActions.setAuthLoading(false)
						);
					} else {
						await loginFirebaseUser(user);
					}
				} else {
					void dispatch(
						UserAuthActions.setAuthLoading(false)
					);
				}
				setLoading(false);
			});
		} catch (err) {
			console.error(err);
		}
	}, [loginFirebaseUser, dispatch, navigate]);

	// Call getActiveSession on component mount
	useEffect(() => {
		void getActiveSession();
	}, []);

	// Redirect to create-password page if user is authenticated but password is not set
	useEffect(() => {
		if (authStatus.isLoggedIn && !isPasswordSet) {
			navigate("/create-password");
		}
	}, [authStatus.isLoggedIn, isPasswordSet, navigate]);

	return (
		<Box className="App">
			{loading && !activeTenant ? (
				<Box className={"page-loader"}>
					<Loader />
				</Box>
			) : (
				<AppRouter />
			)}
			<ToastContainer
				autoClose={1500}
				position="top-center"
			/>
		</Box>
	);
}

export default App;
