import type { TReviewStatistics } from "./sanitization.type";
import type { TSimulationQuestions } from "./simulation.type";

import { parameters } from "../components/analysis/GlobalParameters";

export type TAnalysisState = {
	tapeStats: {
		data: Array<TReviewStatistics> | null;
		loading: boolean;
		error: any;
	};
	tapeData: {
		data: any;
		loading: boolean;
		error: any;
	};
	valuationRun: {
		data: Array<any>;
		loading: boolean;
		error: any;
	};
	allPointData: {
		data: any;
		loading: boolean;
		error: any;
	};
	pointData: {
		data: any;
		loading: boolean;
		error: any;
	};
	jsonParameters: {
		data: TJsonParameters | null;
		loading: boolean;
		error: any;
	};
	simulationParameters: {
		data: {
			parameters: Array<TQuestionObject>;
			isConfirmed: boolean;
		} | null;
		loading: boolean;
		error: any;
	};
};

export type TJsonParameters = {
	globalParameters: Array<TQuestionObject>;
	actions: Array<TActionParameterObject>;
	isConfirmed: boolean;
};

export type TActionParameterObject = {
	parameters: Array<TQuestionObject>;
	meta: {
		displayName: string;
		actionId: string;
	};
	litheData: {
		litheType: "timeseries" | "timepoint";
		answer: any;
		categoryId: string;
		options: Record<string, any>;
	};
};

export type TQuestionObject = {
	question: string;
	questionId: string;
	answers: Array<{
		litheId: string;
		litheName: string;
	}>;
	categoryId?: string;
	answerType:
		| "float_list"
		| "int_list"
		| "tape_filters"
		| "tape_overrides"
		| "int"
		| "option_selection";
	answer: any;
	defaultAnswer: any;
	validations?: any;
	isGenAIEnabled: boolean;
	pythonValue: string;
	naturalTextValue: string;
	label?: string;
	isProcessed: boolean;
	uniqueId?: string;
};

export type THandleFormChange = {
	key: string;
	value: any;
	categoryId: string;
	userInputType?:
		| "natural_language"
		| "option_selection"
		| "python_code";
};

export type TParameterUpdateRequestObject = {
	questionId?: string;
	questionCategoryId: string;
	userInput: string | Array<any>;
	pointId: string;
	userInputType:
		| "natural_language"
		| "python_code"
		| "option_selection";
	isLithe?: boolean;
};

export type TSimulationParameterUpdateRequestObject = {
	questionId: string;
	questionCategoryId: string;
	userInput: string;
	runId: string;
	userInputType:
		| "natural_language"
		| "python_code"
		| "option_selection";
};

export enum AnalysisStageEnum {
	UPLOADED = "UPLOADED",
	PRICING_FAILED = "PRICING_FAILED",
	SANITIZATION_COMPLETED = "SANITIZATION_COMPLETED",
	CASHFLOW_GENERATED = "CASHFLOW_GENERATED",
	PRICING_IN_PROGRESS = "PRICING_IN_PROGRESS",
	PRICING_COMPLETED = "PRICING_COMPLETED",
	TAPE_ALREADY_EXISTS = "TAPE_ALREADY_EXISTS",
	CALIPER_RUN_FAILED = "CALIPER_RUN_FAILED",
	SEND_EMAIL_FAILED = "SEND_EMAIL_FAILED",
	TAPE_SANITIZATION_FAILED = "SANITIZATION_FAILED",
	CASH_FLOW_GENERATION_FAILED = "CASH_FLOW_GENERATION_FAILED",
	NOT_YET_STARTED = "NOT_YET_STARTED",
	IN_PROGRESS = "IN_PROGRESS",
	COMPLETED = "COMPLETED",
	ERROR = "ERROR",
}

export enum RunTypeEnum {
	TRANSITIONS = "00000007-0000-0000-0000-000000000000",
}
