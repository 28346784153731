import React, {
	createContext,
	useCallback,
	useEffect,
	useState,
	type ReactElement,
} from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import type { AssetClassEnum } from "../types/tapes.type";

import { StepEnum } from "../pages/SanitizeTape";
import { SanitizationActions } from "../redux/sanitization/sanitization.slice";
import {
	confirmStatistics,
	getDataFromDataRange,
	updateColumnMappingDataThunk,
	uploadTapeThunk,
} from "../redux/sanitization/sanitization.thunk";
import {
	useAppDispatch,
	useAppSelector,
} from "../redux/store";
import { services } from "../services";

// Create the context
export const SanitizationContext = createContext<any>(null);

// Create the provider component
export const SanitizationContextProvider: React.FC<{
	children: ReactElement;
}> = ({ children }) => {
	const {
		tapeExtraction,
		tapeSanitizationInfo,
		columnMapping,
		submissionStatus,
	} = useAppSelector((state) => state.sanitization);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const [
		activeTapeIdForSanitization,
		setActiveTapeIdForSanitization,
	] = useState<string | null>(null);

	const [activeStep, setActiveStep] =
		useState<StepEnum | null>(null);

	const [sheet, setSheet] = useState<string>("");
	const [headerRange, setHeaderRange] =
		useState<string>("");
	const [dataRange, setDataRange] = useState<string>("");

	const [isDataRangeModified, setIsDataRangeModified] =
		useState<boolean>(false);

	const [
		isColumnMappingModified,
		setIsColumnMappingModified,
	] = useState<boolean>(false);

	const [disableContinue, setDisableContinue] =
		useState<boolean>(false);

	const [loadingContinue, setLoadingContinue] =
		useState<boolean>(false);

	const [isUserInputChanged, setIsUserInputChanged] =
		useState(false);
	const [downloadTapeLoading, setDownloadTapeLoading] =
		useState(false);

	const [reSanitizeModalOpen, setReSanitizeModalOpen] =
		useState(false);

	const cancelReSanitize = useCallback(() => {
		setReSanitizeModalOpen(false);
	}, []);
	const showWarningForReSanitize = useCallback(() => {
		setReSanitizeModalOpen(true);
	}, []);

	const uploadTape = useCallback(
		(
			file: File,
			tapeName: string,
			assetClass: AssetClassEnum
		) => {
			void dispatch(
				uploadTapeThunk({
					file,
					name: tapeName,
					assetClass,
				})
			);
		},
		[dispatch]
	);

	const downloadTape = useCallback(async () => {
		try {
			if (activeTapeIdForSanitization) {
				setDownloadTapeLoading(true);
				toast
					.promise(
						async () => {
							return await services.sanitizationService.downloadTape(
								activeTapeIdForSanitization
							);
						},
						{
							pending: "Downloading tape...",
							success: "Tape downloaded successfully",
							error: "Error downloading tape",
						}
					)
					.then((res) => {
						if (res) {
							const file = URL.createObjectURL(
								new Blob([res])
							);
							const link = document.createElement("a");
							link.href = file;
							const fileName =
								tapeSanitizationInfo?.tapeName ??
								activeTapeIdForSanitization;
							link.setAttribute(
								"download",
								fileName + ".xlsx"
							);
							document.body.appendChild(link);
							link.click();
						}
					})
					.catch((error) => {
						console.error("Error downloading tape", error);
					});
				setTimeout(() => {
					setDownloadTapeLoading(false);
				}, 2000);
			}
		} catch (error) {
			console.error("Error downloading tape", error);
		}
	}, [activeTapeIdForSanitization, tapeSanitizationInfo]);

	const resetEverythingInSanitization = useCallback(() => {
		setActiveStep(null);
		setSheet("");
		setHeaderRange("");
		setDataRange("");
		setIsDataRangeModified(false);
		setIsColumnMappingModified(false);
		setDisableContinue(false);
		setLoadingContinue(false);
		setIsUserInputChanged(false);
		dispatch(SanitizationActions.resetEverything());
	}, [dispatch]);

	// Reset everything in sanitization context when the tape changes
	useEffect(() => {
		if (activeTapeIdForSanitization) {
			return () => {
				resetEverythingInSanitization();
			};
		}
	}, [
		activeTapeIdForSanitization,
		resetEverythingInSanitization,
	]);

	useEffect(() => {
		if (
			tapeExtraction?.recentRangeSelection &&
			tapeExtraction.suggestedRange
		) {
			if (
				tapeExtraction.recentRangeSelection.sheet !==
					tapeExtraction.suggestedRange.sheetName ||
				tapeExtraction.recentRangeSelection.headerRange !==
					tapeExtraction.suggestedRange.headerRange ||
				tapeExtraction.recentRangeSelection.dataRange !==
					tapeExtraction.suggestedRange.bodyRange
			) {
				setIsDataRangeModified(true);
			} else if (tapeExtraction.isModifiedNotConfirmed) {
				setIsDataRangeModified(true);
			} else {
				if (isUserInputChanged) {
					setIsDataRangeModified(true);
				} else setIsDataRangeModified(false);
			}
		}
	}, [
		tapeExtraction?.recentRangeSelection,
		tapeExtraction?.suggestedRange,
		tapeExtraction?.isStepConfirmed,
		tapeExtraction?.isModifiedNotConfirmed,
		isUserInputChanged,
	]);

	useEffect(() => {
		if (activeStep === StepEnum.Mapping) {
			let isAllColumnMapped = false;
			if (columnMapping?.allColumns) {
				isAllColumnMapped = columnMapping.allColumns.every(
					(column) => column.is_user_confirmed
				);
			}
			if (isAllColumnMapped) {
				if (
					tapeSanitizationInfo?.currentStep.path ===
					StepEnum.Mapping
				) {
					setIsColumnMappingModified(true);
				} else setIsColumnMappingModified(false);
				setDisableContinue(false);
			} else {
				setIsColumnMappingModified(true);
				setDisableContinue(true);
			}
		} else if (activeStep === StepEnum.Statistics) {
			setDisableContinue(false);
		}
	}, [
		columnMapping,
		tapeSanitizationInfo?.currentStep,
		activeStep,
	]);

	const validateInput = useCallback(
		(
			sheet: string,
			headerRange: string,
			dataRange: string
		) => {
			const rangeRegex = /^[A-Za-z]+\d+:[A-Za-z]+\d+$/;

			if (sheet.length === 0) {
				// Invalid sheet name
				// Handle the error or display a message
				toast.error("Sheet name is not valid");
				return false;
			}

			if (!rangeRegex.test(headerRange)) {
				// Invalid header range
				// Handle the error or display a message
				toast.error("Header range is not valid");
				return false;
			}

			if (!rangeRegex.test(dataRange)) {
				// Invalid data range
				// Handle the error or display a message
				toast.error("Data range is not valid");
				return false;
			}

			// All inputs are valid
			return true;
		},
		[]
	);

	const reloadTape = useCallback(async () => {
		if (
			activeTapeIdForSanitization &&
			sheet &&
			headerRange &&
			dataRange
		) {
			const isValid = validateInput(
				sheet,
				headerRange,
				dataRange
			);
			if (isValid) {
				await dispatch(
					getDataFromDataRange({
						tapeId: activeTapeIdForSanitization,
						sheet: sheet.trim(),
						headerRange: headerRange.trim(),
						dataRange: dataRange.trim(),
						isStepConfirmed: false,
						isReload: true,
					})
				);
			}
		} else {
			toast.error(
				"Please enter sheet, header and data range"
			);
		}
	}, [
		activeTapeIdForSanitization,
		sheet,
		headerRange,
		dataRange,
		validateInput,
		dispatch,
	]);

	const handleContinue = useCallback(
		async (forceForward?: boolean) => {
			if (
				activeStep &&
				activeTapeIdForSanitization &&
				tapeSanitizationInfo?.currentStep
			) {
				const tapeId = activeTapeIdForSanitization;

				if (
					tapeSanitizationInfo.currentStep.path ===
						StepEnum.Analysis &&
					!forceForward
				) {
					showWarningForReSanitize();
				} else {
					setLoadingContinue(true);
					switch (activeStep) {
						case StepEnum.Extraction:
							if (
								tapeExtraction?.recentRangeSelection &&
								isDataRangeModified
							) {
								if (isUserInputChanged) {
									await reloadTape();
								} else {
									await dispatch(
										getDataFromDataRange({
											tapeId,
											...tapeExtraction.recentRangeSelection,
											isStepConfirmed: true,
										})
									);
								}
							}
							break;
						case StepEnum.Mapping:
							if (isColumnMappingModified) {
								await dispatch(
									updateColumnMappingDataThunk({
										data: {
											is_step_confirmed: true,
											feedback: {},
										},
										tapeId,
									})
								);
							}
							break;
						case StepEnum.Statistics:
							await dispatch(confirmStatistics(tapeId));
							break;
						default:
							break;
					}
					setTimeout(() => {
						setLoadingContinue(false);
					}, 500);
				}
			}
		},
		[
			activeStep,
			activeTapeIdForSanitization,
			tapeSanitizationInfo?.currentStep,
			showWarningForReSanitize,
			tapeExtraction?.recentRangeSelection,
			isDataRangeModified,
			isColumnMappingModified,
			dispatch,
			isUserInputChanged,
			reloadTape,
		]
	);

	const moveToNextStep = useCallback(async () => {
		if (
			activeTapeIdForSanitization &&
			tapeSanitizationInfo
		) {
			setLoadingContinue(true);
			const updatedCurrentStep =
				await services.sanitizationService.getTapeInfo(
					activeTapeIdForSanitization
				);

			if (updatedCurrentStep) {
				dispatch(
					SanitizationActions.updateCurrentStep(
						updatedCurrentStep
					)
				);
			}

			const nextStep = tapeSanitizationInfo.allSteps.find(
				(el) => el.path === activeStep
			)?.nextStep;
			if (nextStep && nextStep !== "analysis") {
				setIsDataRangeModified(false);
				setIsColumnMappingModified(false);
				navigate(
					`/sanitize/${nextStep}/${activeTapeIdForSanitization}`
				);
			} else {
				navigate(
					`/analysis/${activeTapeIdForSanitization}`
				);
			}
			setTimeout(() => {
				setLoadingContinue(false);
			}, 500);
		}
	}, [
		activeStep,
		activeTapeIdForSanitization,
		dispatch,
		navigate,
		tapeSanitizationInfo,
	]);

	useEffect(() => {
		if (
			submissionStatus?.submissionCreated &&
			submissionStatus.isSubmissionSuccessful
		) {
			switch (submissionStatus.currentStep) {
				case "extraction": {
					toast.success("Data extraction completed");
					break;
				}
				case "mapping": {
					toast.success("Column mapping completed");
					break;
				}
				case "statistics": {
					toast.success(
						"Tape extraction and sanitization completed"
					);
					break;
				}
				default: {
					toast.success("Submission successful");
					break;
				}
			}
			void moveToNextStep();
		} else if (
			submissionStatus?.submissionCreated &&
			!submissionStatus.isSubmissionSuccessful
		) {
			// Handle error
			toast.error(
				(submissionStatus.error?.msg as string) ||
					"Error occurred while submitting your response!"
			);
			dispatch(
				SanitizationActions.updateSanitizationSubmissionStatus(
					null
				)
			);
		}
	}, [dispatch, moveToNextStep, submissionStatus]);

	const value = {
		activeTapeIdForSanitization,
		setActiveTapeIdForSanitization,
		sheet,
		setSheet,
		headerRange,
		setHeaderRange,
		dataRange,
		setDataRange,
		isDataRangeModified,
		handleContinue,
		isColumnMappingModified,
		setIsColumnMappingModified,
		activeStep,
		setActiveStep,
		moveToNextStep,
		disableContinue,
		setDisableContinue,
		loadingContinue,
		isUserInputChanged,
		setIsUserInputChanged,
		resetEverythingInSanitization,
		uploadTape,
		downloadTape,
		downloadTapeLoading,
		reSanitizeModalOpen,
		setReSanitizeModalOpen,
		cancelReSanitize,
		showWarningForReSanitize,
	};

	return (
		<SanitizationContext.Provider value={value}>
			{children}
		</SanitizationContext.Provider>
	);
};
