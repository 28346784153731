// Importing the necessary services
import { AnalysisService } from "./analysis.service";
import { LitheService } from "./lithes.services";
import { SanitizationService } from "./sanitization.service";
import { TenantService } from "./tenant.service";
import { UserService } from "./user.service";

// Creating an object that holds instances of the services.
// This object will be used to access the services from the components.
export const services = {
	userServiceInstance: new UserService(), // Instance of UserService
	tenantServiceInstance: new TenantService(), // Instance of TenantService
	analysisServiceInstance: new AnalysisService(), // Instance of AnalysisService
	sanitizationService: new SanitizationService(), // Instance of SanitizationService
	litheService: new LitheService(), // Instance of LitheService
};
