import { createAsyncThunk } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";

import { services } from "../../services";
import { type TUserAuthState } from "../../types/user.type";

// This thunk is responsible for handling password update.
export const passwordUpdatedThunk = createAsyncThunk(
	"auth/passwordUpdated",
	async (password: string) => {
		return await services.userServiceInstance.changePassword(
			password
		);
	}
);

// This thunk is responsible for handling user login. This is source of truth for user authentication.
export const userLoginThunk = createAsyncThunk(
	"auth/userLogin",
	async (arg, { getState }) => {
		const data =
			await services.userServiceInstance.getUserMeta();
		const { user } = getState() as TUserAuthState;
		const decodedToken = jwtDecode(user?.token as string);
		data.isPasswordSet = (
			decodedToken as any
		).email_verified;
		return data;
	}
);

export const getAllUsersInTenantThunk = createAsyncThunk(
	"auth/getAllUsersInTenant",
	async (_, { rejectWithValue }) => {
		try {
			const data =
				await services.userServiceInstance.getAllUsers();
			return data;
		} catch (error: any) {
			console.error("redux error", error);
			return rejectWithValue({
				...error.response.data,
				msg: `Error occurred: ${error.response.data.msg}`,
			});
		}
	}
);
