import type {
	TLitheCreationData,
	TLitheQuestionRequestObject,
	TLitheSearchResponseProps,
} from "../types/lithe.type";
import type {
	TTapeSearchParameters,
	TTapeSearchResponseProps,
} from "../types/tapes.type";
import type { AxiosRequestConfig } from "axios";

import { getEndpoint } from "../endpoints";

import {
	httpServiceInstance,
	type HttpService,
} from "./http.service";

export class LitheService {
	private static readonly instance: LitheService;
	private static http: HttpService;
	private static endpoint: string;
	private static base_url: string;
	constructor() {
		LitheService.http = httpServiceInstance;
		LitheService.endpoint = getEndpoint();
		LitheService.base_url = `${LitheService.endpoint}/api/lithes`;
	}

	async getAllLithes(): Promise<any> {
		const url = `${LitheService.base_url}`;
		const { data } = await LitheService.http.get<any>(url);
		return data;
	}

	async litheSearch(
		request: TTapeSearchParameters,
		config?: AxiosRequestConfig
	): Promise<TLitheSearchResponseProps> {
		const url = `${LitheService.base_url}/search?page=${request.page}`;
		try {
			const { data } =
				await LitheService.http.post<TLitheSearchResponseProps>(
					url,
					{
						data: request.form,
						...config,
					}
				);
			return data;
		} catch (error: any) {
			return {
				lithes: [],
				count: 0,
				error: error.response,
			};
		}
	}

	async createLithe(
		litheCreationData: TLitheCreationData
	): Promise<any> {
		const url = `${LitheService.base_url}`;
		const { data } = await LitheService.http.post<any>(
			url,
			{
				data: {
					lithe_name: litheCreationData.litheName,
					asset_class_id: litheCreationData.assetClassId,
					lithe_creation_type:
						litheCreationData.litheCreationType,
					lithe_scope: litheCreationData.litheScope,
				},
			}
		);
		return data;
	}

	async getLithe(litheId: string): Promise<any> {
		const url = `${LitheService.base_url}/${litheId}/meta`;
		const { data } = await LitheService.http.get<any>(url);
		return data;
	}

	async getLitheSteps(litheId: string): Promise<any> {
		const url = `${LitheService.base_url}/${litheId}/steps`;
		const { data } = await LitheService.http.get<any>(url);
		return data;
	}

	async getLitheSpecsQuestions(
		litheId: string
	): Promise<any> {
		const url = `${LitheService.base_url}/${litheId}/specs/questions`;
		const { data } = await LitheService.http.get<any>(url);
		return data;
	}

	async updateLitheSpecsQuestions(
		requestObject: TLitheQuestionRequestObject
	): Promise<any> {
		const litheId = requestObject.litheId;
		const url = `${LitheService.base_url}/${litheId}/specs/questions`;
		const { data } = await LitheService.http.post<any>(
			url,
			{
				data: {
					question_id: requestObject.questionId,
					question_category_id:
						requestObject.questionCategoryId,
					user_input: requestObject.userInput,
					user_input_type: requestObject.userInputType,
				},
			}
		);
		return data;
	}

	async confirmSpecs(litheId: string): Promise<any> {
		const url = `${LitheService.base_url}/${litheId}/specs`;
		const { data } = await LitheService.http.post<any>(
			url,
			{
				data: {
					is_step_confirmed: true,
				},
			}
		);
		return data;
	}

	async getLitheDataVizQuestions(
		litheId: string
	): Promise<any> {
		const url = `${LitheService.base_url}/${litheId}/data_viz/questions`;
		const { data } = await LitheService.http.get<any>(url);
		return data;
	}

	async getLitheDataVizResults(
		litheId: string,
		config?: AxiosRequestConfig
	): Promise<any> {
		const url = `${LitheService.base_url}/${litheId}/data_viz`;
		try {
			const { data } = await LitheService.http.get<any>(
				url,
				{
					signal: config?.signal,
				}
			);
			return data;
		} catch (error: any) {
			throw error.response;
		}
	}

	async updateLitheDataVizQuestions(
		requestObject: TLitheQuestionRequestObject
	): Promise<any> {
		const litheId = requestObject.litheId;
		const url = `${LitheService.base_url}/${litheId}/data_viz/questions`;
		const { data } = await LitheService.http.post<any>(
			url,
			{
				data: {
					question_id: requestObject.questionId,
					question_category_id:
						requestObject.questionCategoryId,
					user_input: requestObject.userInput,
					user_input_type: requestObject.userInputType,
				},
			}
		);
		return data;
	}

	async processDataViz(litheId: string): Promise<any> {
		const url = `${LitheService.base_url}/${litheId}/data_viz`;
		const { data } = await LitheService.http.post<any>(
			url,
			{
				data: {
					is_step_confirmed: false,
				},
			}
		);
		return data;
	}

	async confirmDataViz(litheId: string): Promise<any> {
		const url = `${LitheService.base_url}/${litheId}/data_viz`;
		const { data } = await LitheService.http.post<any>(
			url,
			{
				data: {
					is_step_confirmed: true,
				},
			}
		);
		return data;
	}

	async getLitheCashflowVizQuestions(
		litheId: string
	): Promise<any> {
		const url = `${LitheService.base_url}/${litheId}/cashflow_viz/questions`;
		const { data } = await LitheService.http.get<any>(url);
		return data;
	}

	async getLitheCashflowVizResults(
		litheId: string,
		config?: AxiosRequestConfig
	): Promise<any> {
		const url = `${LitheService.base_url}/${litheId}/cashflow_viz`;
		try {
			const { data } = await LitheService.http.get<any>(
				url,
				{
					signal: config?.signal,
				}
			);
			return data;
		} catch (error: any) {
			throw error.response;
		}
	}

	async updateLitheCashflowVizQuestions(
		requestObject: TLitheQuestionRequestObject
	): Promise<any> {
		const litheId = requestObject.litheId;
		const url = `${LitheService.base_url}/${litheId}/cashflow_viz/questions`;
		const { data } = await LitheService.http.post<any>(
			url,
			{
				data: {
					question_id: requestObject.questionId,
					question_category_id:
						requestObject.questionCategoryId,
					user_input: requestObject.userInput,
					user_input_type: requestObject.userInputType,
				},
			}
		);
		return data;
	}

	async processCashflowViz(litheId: string): Promise<any> {
		const url = `${LitheService.base_url}/${litheId}/cashflow_viz`;
		const { data } = await LitheService.http.post<any>(
			url,
			{
				data: {
					is_step_confirmed: false,
				},
			}
		);
		return data;
	}

	async confirmCashflowViz(litheId: string): Promise<any> {
		const url = `${LitheService.base_url}/${litheId}/cashflow_viz`;
		const { data } = await LitheService.http.post<any>(
			url,
			{
				data: {
					is_step_confirmed: true,
				},
			}
		);
		return data;
	}

	async getLitheProbsQuestions(
		litheId: string
	): Promise<any> {
		const url = `${LitheService.base_url}/${litheId}/probs/questions`;
		const { data } = await LitheService.http.get<any>(url);
		return data;
	}

	async updateLitheProbsQuestions(
		requestObject: TLitheQuestionRequestObject
	): Promise<any> {
		const litheId = requestObject.litheId;
		const url = `${LitheService.base_url}/${litheId}/probs/questions`;
		const { data } = await LitheService.http.post<any>(
			url,
			{
				data: {
					question_id: requestObject.questionId,
					question_category_id:
						requestObject.questionCategoryId,
					user_input: requestObject.userInput,
					user_input_type: requestObject.userInputType,
				},
			}
		);
		return data;
	}

	async getLitheProbsResults(
		litheId: string,
		config?: AxiosRequestConfig
	): Promise<any> {
		const url = `${LitheService.base_url}/${litheId}/probs`;
		try {
			const { data } = await LitheService.http.get<any>(
				url,
				{
					signal: config?.signal,
				}
			);
			return data;
		} catch (error: any) {
			throw error.response;
		}
	}

	async processProbs(litheId: string): Promise<any> {
		const url = `${LitheService.base_url}/${litheId}/probs`;
		const { data } = await LitheService.http.post<any>(
			url,
			{
				data: {
					is_step_confirmed: false,
				},
			}
		);
		return data;
	}

	async confirmProbs(litheId: string): Promise<any> {
		const url = `${LitheService.base_url}/${litheId}/probs`;
		const { data } = await LitheService.http.post<any>(
			url,
			{
				data: {
					is_step_confirmed: true,
				},
			}
		);
		return data;
	}

	async getLitheRFSpecsQuestions(
		litheId: string
	): Promise<any> {
		const url = `${LitheService.base_url}/${litheId}/rf_specs/questions`;
		const { data } = await LitheService.http.get<any>(url);
		return data;
	}

	async updateLitheRFSpecsQuestions(
		requestObject: TLitheQuestionRequestObject
	): Promise<any> {
		const litheId = requestObject.litheId;
		const url = `${LitheService.base_url}/${litheId}/rf_specs/questions`;
		const { data } = await LitheService.http.post<any>(
			url,
			{
				data: {
					question_id: requestObject.questionId,
					question_category_id:
						requestObject.questionCategoryId,
					user_input: requestObject.userInput,
					user_input_type: requestObject.userInputType,
				},
			}
		);
		return data;
	}

	async getLitheApprovalInfo(
		litheId: string
	): Promise<any> {
		const url = `${LitheService.base_url}/${litheId}/approval`;
		const { data } = await LitheService.http.get<any>(url);
		return data;
	}

	async confirmLitheApprovalInfo(
		litheId: string
	): Promise<any> {
		const url = `${LitheService.base_url}/${litheId}/approval`;
		const { data } = await LitheService.http.post<any>(
			url,
			{
				data: {
					is_step_confirmed: true,
				},
			}
		);
		return data;
	}

	async confirmLitheRFSpecs(litheId: string): Promise<any> {
		const url = `${LitheService.base_url}/${litheId}/rf_specs`;
		const { data } = await LitheService.http.post<any>(
			url,
			{
				data: {
					is_step_confirmed: true,
				},
			}
		);
		return data;
	}

	async getLitheRawProbData(litheId: string): Promise<any> {
		const url = `${LitheService.base_url}/${litheId}/raw_probs/data`;
		const { data } = await LitheService.http.get<any>(url);
		return data;
	}

	async updateLitheRawProbData(
		litheId: string,
		data: any
	): Promise<any> {
		const url = `${LitheService.base_url}/${litheId}/raw_probs/data`;
		const { data: res } = await LitheService.http.post<any>(
			url,
			{
				data,
			}
		);
		return res;
	}

	async processRawProbs(litheId: string): Promise<any> {
		const url = `${LitheService.base_url}/${litheId}/raw_probs`;
		const { data } = await LitheService.http.post<any>(
			url,
			{
				data: {
					is_step_confirmed: true,
				},
			}
		);
		return data;
	}

	async checkForUniqueLitheName(
		litheName: string,
		abortSignal: AbortSignal
	): Promise<any> {
		const url = `${LitheService.base_url}/does_name_exist`;
		const config = {
			data: {
				lithe_name: litheName,
			},
			signal: abortSignal,
		};
		const { data: response } =
			await LitheService.http.post<any>(url, config);
		return response;
	}
}
