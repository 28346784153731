import { Button, Input, Option, Select } from "@mui/joy";
import { Box } from "@mui/material";
import { FiExternalLink } from "@react-icons/all-files/fi/FiExternalLink";
import { IoIosArrowDown } from "@react-icons/all-files/io/IoIosArrowDown";
import {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
	type ChangeEvent,
	type FC,
} from "react";
import Skeleton from "react-loading-skeleton";

import type { THandleCheckContinueProps } from "../../pages/SanitizeTape";

import { SanitizationContext } from "../../contexts/sanitizationContext";
import {
	getDataFromDataRange,
	getDataRangeThunk,
	postPreferencesThunk,
} from "../../redux/sanitization/sanitization.thunk";
import {
	useAppDispatch,
	useAppSelector,
} from "../../redux/store";
import { CoachMark } from "../../shared/CoachMark";
import { LoaderContainer } from "../../shared/Loader";
import textContent from "../../textContent/text.json";

export type TSanitizeComponentsBaseProps = {
	checkContinue: THandleCheckContinueProps;
	handleCheckContinue: (
		data: THandleCheckContinueProps
	) => void;
};

export const PreviewButton = () => {
	const { downloadTape, downloadTapeLoading } = useContext(
		SanitizationContext
	);
	return (
		<Button
			className={"preview-button"}
			startDecorator={<FiExternalLink />}
			onClick={downloadTape}
			loading={downloadTapeLoading}
			disabled={downloadTapeLoading}
		>
			View Tape
		</Button>
	);
};

const DataRange: FC = () => {
	const dispatch = useAppDispatch();

	const text = useMemo(() => textContent.dataRange, []);

	const {
		tapeExtraction,
		tapeExtractionStatus,
		tapeSanitizationInfo,
		preferences,
	} = useAppSelector((state) => state.sanitization);

	const {
		activeTapeIdForSanitization,
		setDisableContinue,
		isDataRangeModified,
		setIsUserInputChanged,
		sheet,
		setSheet,
		headerRange,
		setHeaderRange,
		dataRange,
		setDataRange,
	} = useContext(SanitizationContext);

	const getDataRange = useCallback(
		async (tapeId: string) => {
			await dispatch(getDataRangeThunk(tapeId));
		},
		[dispatch]
	);

	const [coachMarkSequence, setCoachMarkSequence] =
		useState(0);

	const handleCoachMarkSequence = useCallback(
		(num: number) => {
			setCoachMarkSequence(num + 1);
			if (num === 2) {
				void dispatch(
					postPreferencesThunk({
						ui: {
							is_sanitization_tutorial_called: true,
						},
					})
				);
			}
		},
		[dispatch]
	);
	useEffect(() => {
		if (preferences.showDataRangeCoachMark) {
			if (tapeSanitizationInfo && tapeExtraction) {
				if (
					tapeExtraction.isModifiedNotConfirmed ??
					isDataRangeModified
				) {
					if (coachMarkSequence < 3) {
						setDisableContinue(true);
					} else {
						setDisableContinue(false);
					}
				} else if (
					tapeExtraction.isStepConfirmed &&
					!isDataRangeModified
				) {
					setCoachMarkSequence(3);
					setDisableContinue(false);
				}
			}
		} else {
			setDisableContinue(false);
		}
	}, [
		coachMarkSequence,
		isDataRangeModified,
		setDisableContinue,
		tapeSanitizationInfo?.currentStep.path,
		tapeExtraction,
		tapeSanitizationInfo,
		preferences.showDataRangeCoachMark,
	]);

	useEffect(() => {
		if (activeTapeIdForSanitization) {
			void getDataRange(
				activeTapeIdForSanitization as string
			);
		}
	}, [activeTapeIdForSanitization, getDataRange]);

	const handleSheetChange = useCallback(
		(value: string) => {
			if (
				value === tapeExtraction?.suggestedRange?.sheetName
			) {
				setSheet(tapeExtraction.suggestedRange.sheetName);
				setHeaderRange(
					tapeExtraction.suggestedRange.headerRange
				);
				setDataRange(
					tapeExtraction.suggestedRange.bodyRange
				);
			} else {
				setSheet(value);
				setHeaderRange("");
				setDataRange("");
			}
		},
		[
			setDataRange,
			setHeaderRange,
			setSheet,
			tapeExtraction?.suggestedRange?.bodyRange,
			tapeExtraction?.suggestedRange?.headerRange,
			tapeExtraction?.suggestedRange?.sheetName,
		]
	);

	const handleHeaderRangeChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			setHeaderRange(event.target.value.trim());
		},
		[setHeaderRange]
	);

	const handleDataRangeChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			setDataRange(event.target.value.trim());
		},
		[setDataRange]
	);

	useEffect(() => {
		if (
			tapeExtraction?.suggestedRange &&
			tapeExtraction.shortPreview === undefined &&
			activeTapeIdForSanitization &&
			!tapeExtractionStatus.shortPreviewLoading &&
			!tapeExtractionStatus.error
		) {
			if (
				sheet === "" ||
				headerRange === "" ||
				dataRange === ""
			) {
				setSheet(tapeExtraction.suggestedRange.sheetName);
				setHeaderRange(
					tapeExtraction.suggestedRange.headerRange
				);
				setDataRange(
					tapeExtraction.suggestedRange.bodyRange
				);
			}
			void dispatch(
				getDataFromDataRange({
					tapeId: activeTapeIdForSanitization,
					sheet: tapeExtraction.suggestedRange.sheetName,
					headerRange:
						tapeExtraction.suggestedRange.headerRange
							.toUpperCase()
							.trim(),
					dataRange: tapeExtraction.suggestedRange.bodyRange
						.toUpperCase()
						.trim(),
					isStepConfirmed: false,
				})
			);
		}
	}, [
		dataRange,
		dispatch,
		headerRange,
		sheet,
		tapeExtraction?.suggestedRange,
		activeTapeIdForSanitization,
		tapeExtraction?.shortPreview,
		tapeExtraction?.recentRangeSelection?.sheet,
		tapeExtraction?.recentRangeSelection?.headerRange,
		tapeExtraction?.recentRangeSelection?.dataRange,
		tapeExtractionStatus.shortPreviewLoading,
		tapeExtractionStatus.error,
		setSheet,
		setHeaderRange,
		setDataRange,
	]);

	useEffect(() => {
		if (
			tapeExtraction?.recentRangeSelection &&
			(sheet !==
				tapeExtraction.recentRangeSelection.sheet ||
				headerRange.toUpperCase() !==
					tapeExtraction.recentRangeSelection.headerRange ||
				dataRange.toUpperCase() !==
					tapeExtraction.recentRangeSelection.dataRange ||
				tapeExtractionStatus.error)
		) {
			setIsUserInputChanged(true);
		} else if (
			!tapeExtraction?.recentRangeSelection &&
			sheet &&
			headerRange &&
			dataRange
		) {
			setIsUserInputChanged(true);
		} else setIsUserInputChanged(false);
	}, [
		tapeExtraction?.recentRangeSelection,
		sheet,
		headerRange,
		dataRange,
		tapeExtractionStatus.error,
		setIsUserInputChanged,
	]);

	useEffect(() => {
		if (tapeExtraction?.shortPreview) {
			const table: Array<Array<string | number>> = [];
			const columns = tapeExtraction.shortPreview[0].length;
			for (let i = 0; i < columns; i++) {
				table.push([]);
			}
			for (
				let i = 0;
				i < tapeExtraction.shortPreview.length;
				i++
			) {
				for (
					let j = 0;
					j < tapeExtraction.shortPreview[i].length;
					j++
				) {
					table[j].push(tapeExtraction.shortPreview[i][j]);
				}
			}
		}
	}, [tapeExtraction?.shortPreview]);

	return (
		<Box className={"data-range"}>
			<Box className={"data-actions"}>
				<Box className={"data-selection-bar"}>
					<Box className={"sheet-input"}>
						<Select
							className="sheet-input-field"
							startDecorator={<label>sheet</label>}
							indicator={<IoIosArrowDown />}
							value={sheet}
							onChange={(e, value) => {
								handleSheetChange(value as string);
							}}
						>
							{tapeExtraction?.sheets.map(
								(sheet: string) => (
									<Option
										value={sheet}
										key={sheet}
									>
										{sheet}
									</Option>
								)
							)}
						</Select>
						<CoachMark
							position="bottom"
							show={Boolean(
								preferences.showDataRangeCoachMark &&
									coachMarkSequence === 0 &&
									tapeExtraction?.sheets
							)}
						>
							<Box className={"data-range-coach-mark"}>
								<h4>{text.coachMarks.sheet.heading}</h4>
								<p>{text.coachMarks.sheet.description}</p>
								<span
									className={"outline-btn"}
									onClick={handleCoachMarkSequence.bind(
										null,
										0
									)}
								>
									Okay, got it
								</span>
							</Box>
						</CoachMark>
					</Box>

					<Box className={"range-input"}>
						<Input
							startDecorator={<label>header range</label>}
							className="range-input-field"
							value={headerRange}
							onChange={handleHeaderRangeChange}
							placeholder="A1:Z1"
						/>
						<CoachMark
							position="bottom"
							show={Boolean(
								preferences.showDataRangeCoachMark &&
									coachMarkSequence === 1 &&
									tapeExtraction?.sheets
							)}
						>
							<Box className={"data-range-coach-mark"}>
								<h4>{text.coachMarks.header.heading}</h4>
								<p>{text.coachMarks.header.description}</p>
								<span
									className={"outline-btn"}
									onClick={handleCoachMarkSequence.bind(
										null,
										1
									)}
								>
									Okay, got it
								</span>
							</Box>
						</CoachMark>
					</Box>
					<Box className={"range-input"}>
						<Input
							startDecorator={<label>data range</label>}
							className="range-input-field"
							value={dataRange}
							onChange={handleDataRangeChange}
							placeholder="A2:Z100"
						/>
						<CoachMark
							position="bottom"
							show={Boolean(
								preferences.showDataRangeCoachMark &&
									coachMarkSequence === 2 &&
									tapeExtraction?.sheets
							)}
						>
							<Box className={"data-range-coach-mark"}>
								<h4>{text.coachMarks.range.heading}</h4>
								<p>{text.coachMarks.range.description}</p>
								<span
									className={"outline-btn"}
									onClick={handleCoachMarkSequence.bind(
										null,
										2
									)}
								>
									Okay, got it
								</span>
							</Box>
						</CoachMark>
					</Box>
				</Box>
			</Box>
			<Box className={"data-preview"}>
				{tapeExtractionStatus.shortPreviewLoading ? (
					<LoaderContainer width={50} />
				) : tapeExtraction?.shortPreview ? (
					<table>
						<thead>
							<tr>
								{tapeExtraction.shortPreview[0]?.map(
									(head, index) => (
										<th key={String(index) + String(head)}>
											{head}
										</th>
									)
								)}
							</tr>
						</thead>
						<tbody>
							{tapeExtraction.shortPreview.map(
								(row, index) => {
									if (index > 0) {
										if (index !== 5)
											return (
												<tr key={index}>
													{row.map((cell, index) => (
														<td
															key={
																String(index) + String(cell)
															}
														>
															<pre>{cell}</pre>
														</td>
													))}
												</tr>
											);
										else {
											return (
												<>
													<tr key={index}>
														{row.map((cell, index) => (
															<td
																key={
																	String(index) +
																	String(cell)
																}
															>
																<pre>{cell}</pre>
															</td>
														))}
													</tr>
													{[0, 1, 2, 3, 4].map((elm) => (
														<tr key={elm}>
															{row.map((cell, index) => (
																<td key={index}>
																	<Skeleton
																		enableAnimation={false}
																	/>
																</td>
															))}
														</tr>
													))}
												</>
											);
										}
									} else return null;
								}
							)}
						</tbody>
					</table>
				) : (
					<Box className={"invalid-range-selection"}>
						<h4>
							{tapeExtractionStatus.statusCode === 400
								? text.errorMsg.status400.heading
								: text.errorMsg.status500.heading}
						</h4>
						<p>{tapeExtractionStatus.error}</p>
						<p>
							{tapeExtractionStatus.statusCode === 400
								? text.errorMsg.status400.description
								: text.errorMsg.status500.description}
						</p>
					</Box>
				)}
			</Box>
		</Box>
	);
};

export default DataRange;
